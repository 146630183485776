import React, { forwardRef } from 'react';
import { Alert } from '@mui/material';
import { styled } from '@mui/system';

const StyledAlert = styled(Alert)((props) => ({
  '& .MuiAlert-icon': {
    fontSize: '20px',
  },
  '& .MuiAlert-message': {
    fontSize: '14px',
    fontWeight: 400,
    color: props.severity === 'success' ? '#385c3a' : '#6e3433',
  },
  
}));

const StyledCustomAlert = forwardRef(({ severity, message, onClose, width }, ref) => {
    return (
      <StyledAlert ref={ref} onClose={onClose} severity={severity} sx={{ width: width }}>
        {message}
      </StyledAlert>
    );
  });

  StyledCustomAlert.displayName = 'StyledCustomAlert';

export default StyledCustomAlert;
