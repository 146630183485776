import { literals } from "../../../enums/literalCodes";
import dayjs from 'dayjs';

const generateCalEvent = (title, date, backgroundColor) => ({
  title,
  date,
  backgroundColor,
  borderColor: backgroundColor
});

const FeeCalendarUtils = {

  returnStatusCode: (statusId) => {
    switch (statusId) {
      case -1:
        return literals.LOCKED;
      case -2:
        return literals.FINALIZED;
      case 1:
        return literals.LOCKED;
      case 2:
        return literals.FINALIZED;
      case 3:
        return literals.POSTED;
      case 4:
        return literals.TAGGED;
      case 5:
        return literals.BILLED;
      case 6:
        return literals.PAID;
      case 7:
        return literals.ADJUSTED;
      case 8:
        return literals.REVERSED;
      case 9:
        return literals.CLOSED;
      case 10:
        return literals.VOID;
      case 9998:
        return literals.NOCHARGE;
      case 9999:
        return literals.WRITEOFF;
      default:
        return literals.DRAFT;
    }
  },
  calculateInfo : (data) => {
    let billable = 0 ,nonBillable = 0, totalHours = 0, billableAmount = 0, noOfEntries = data?.length || 0, adminHours = 0; // billableHours, nonBillableHours, total hours, billableAmount

    data.forEach((item) => {
      const { billable: isBillable, hours, amount } = item;
      if (isBillable === 1) {
        billable += hours;
        billableAmount += amount;
      } else {
        nonBillable += hours;
      }
      totalHours += hours;
    });
    
    return {billable, nonBillable, totalHours, billableAmount, noOfEntries, adminHours};
  },
  calculateYearlyInfo : (data) => {
    let billable = 0 ,nonBillable = 0, totalHours = 0; // billableHours, nonBillableHours, 

    data.forEach((item) => {
      const { billableHours, nonBillableHours } = item;
      totalHours += billableHours + nonBillableHours ;
      billable += billableHours;
      nonBillable += nonBillableHours;
    });
    
    return {billable, nonBillable, totalHours};
  },

  groupTimeEntries : (data) => {

    let totalHours = 0 , totalAmount = 0, Client = "", Matter = "", groupedEntries = [];
    
    let {clientCode,clientDescription,matterDescription,matterCode} = data[0];
    Client = clientCode + " " + clientDescription;
    Matter = matterCode + " " + matterDescription;

    data.forEach((item) => {
      const { hours, amount,activityDate,narrative,statusId,activityId } = item;
      groupedEntries.push(
        {
          Client,
          Matter,
          hours,
          amount,
          activityDate,
          narrative,
          activityId,
          status: FeeCalendarUtils.returnStatusCode(statusId)
        }
      )
      totalHours += hours;
      totalAmount += amount;
    });
    
    return {totalHours, totalAmount, Client, Matter, groupedEntries};
  },

  getCalendarEvents : (content, groupByItem) => {
    const groupedData = Object.groupBy(content, (item) => groupByItem ? item[groupByItem] : item.activityDate);
    const events = [];
  
    for (const date in groupedData) {
      if(groupByItem){

        const {billable, nonBillable, totalHours} = FeeCalendarUtils.calculateYearlyInfo(groupedData[date]);
       
        events.push(
          generateCalEvent(`${billable.toFixed(2)} Billable`, date, '#A5CD3799'),
          generateCalEvent(`${nonBillable.toFixed(2)} Non-Billable`, date, '#FFAF0099'),
          generateCalEvent(`${totalHours.toFixed(2)} Hours`, date, 'transparent'),
        );
      }
      else{  
        const {billable, nonBillable, totalHours} = FeeCalendarUtils.calculateInfo(groupedData[date]);
       
        events.push(
          generateCalEvent(`${billable.toFixed(2)} Billable`, date, '#A5CD3799'),
          generateCalEvent(`${nonBillable.toFixed(2)} Non-Billable`, date, '#FFAF0099'),
          generateCalEvent(`${totalHours.toFixed(2)} Hours`, date, 'transparent'),
        );
      }
        
        
    }
    return { events };
  },

  getSummary: (content) => {
    const summary = FeeCalendarUtils.calculateInfo(content);
    return {summary};
  },
  getMonthEntries: (content) => {

    const groupedData = Object.groupBy(content, (item) => item.customerId);
    const entries = [];
    for (const date in groupedData) {
      entries.push(FeeCalendarUtils.groupTimeEntries(groupedData[date]));        
    }
    return { entries };
  },

  percentage: (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  },
  
  hasNoEvent: (text) => {
    return /^\d+$/.test(text);
  },

  returnSummaryHeading: (calView, dayDate) => {
    const {type, isMonthOrday} = calView;

    if(type === 'year' && isMonthOrday === 'month'){
      return literals.SUMMARY_YEAR_VIEW
    }
    else if(type === 'month' && isMonthOrday === 'month'){ 
       return literals.SUMMARY_MONTH_VIEW 
      }
    else if(type === 'month' && isMonthOrday === 'day'){ 
      return literals.SUMMARY_DAY_VIEW 
    }
    else if(type === 'year' && isMonthOrday === 'day'){ 
      return `Summary (${dayjs(`${dayDate}`).format('DD/MM/YYYY')})`;
    }
  },

  returnEntriesHeading: (calView, viewType) => {
    const {type, isMonthOrday} = calView;

    if(type === 'year' && isMonthOrday === 'month' && viewType !== 'list'){
      return literals.TIME_ENTRIES_FOR_THIS_YEAR
    }
    else if(type === 'month' && isMonthOrday === 'month' && viewType !== 'list'){ 
      return literals.TIME_ENTRIES_FOR_THIS_MONTH 
      }
    else if(isMonthOrday === 'day'){ 
      return literals.TIME_ENTRIES_FOR_THIS_DAY 
    }
    else if(viewType === 'list'){
      return literals.TIME_ENTRIES_FOR_THIS_CLIENT_MATTER
    }
  },

  getEntriesByRange: (data) => {
    const entries = [];
    for (const item in data) {
      let {clientCode,clientDescription,matterDescription,matterCode, totalAmount, totalHours, clientId,customerId: matterId} = data[item];
      const Client = clientCode + " " + clientDescription;
      const Matter = matterCode + " " + matterDescription;

      entries.push({
        Client, 
        Matter,
        totalAmount, 
        totalHours,
        clientId,
        matterId
      });        
    }
    return { entries };
  },
  
  summaryIsLoaded : (summary, field) => {
    return summary[field] !== null && summary[field] !== undefined
  },

  entriesAreLoaded : (data, calendarView) => {
    const {type, isMonthOrday} = calendarView;

    if(type === 'year' && isMonthOrday === 'month' && data?.length <= 0){
      return true;
    }
    else if(type === 'year' && isMonthOrday === 'day' && data?.length <= 0){
      return true;
    }
    else if(type === 'month' && isMonthOrday === 'month' && data?.length <= 0){
      return true;
    }
    else if(type === 'month' && isMonthOrday === 'day' && data?.length <= 0){
      return true;
    }

    return false;
  },
  currencyFormatter : (amount) => {
    // Convert amount to a number if it's not already
    const numberAmount = Number(amount);
    
    // Check if the conversion is successful
    if (isNaN(numberAmount)) {
      return "-";
    }
    
    // Format the amount as currency with the US dollar sign
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2 
    }).format(numberAmount);
    
    return formattedAmount.replace('$', '$ ');
  }
}
export default FeeCalendarUtils;