const layouts = [
    { i: "Time Entry Grid", x: 0, y: 0, w: 12, minW: 4, h: 12, minH: 3, maxH: 12, isResizable: true, static: false },
    { i: "Time Entry", x: 0, y: 0, w: 6, minW: 5, h: 12.6, minH: 12.6, maxH: 12.6, isResizable: true, static: false },
    { i: "Recent Client Matters", x: 0, y: 0, w: 6, minW: 3, h: 12.1, minH: 12.1, maxH: 12.1, isResizable: true, static: false },
    { i: "Expense Entry", x: 0, y: 0, w: 6, minW: 4, h: 12.3, minH: 12.3, maxH: 12.3, isResizable: true, static: false },
    { i: "Expense Entry Grid", x: 0, y: 0, w: 12, minW: 4, h: 12, minH: 3, maxH: 12, isResizable: true, static: false },
    { i: "Fee Calendar", x: 0, y: 0, w: 12, minW: 12, h: 19.7, minH: 19.7, maxH: 19.7, isResizable: false, static: false },
    { i: "LawToolBox Docketing", x: 0, y: 0, w: 12, minW: 12, h: 17.4, minH: 17.4, maxH: 17.4, isResizable: false, static: false },
    { i: "Client Inquiry", x: 0, y: 0, w: 12, minW: 12, h: 18.3, minH: 18.3, maxH: 18.3, isResizable: false, static: false },

];
export default layouts;