import React, { createContext, useContext, useState } from 'react';
const LtbInputContext = createContext();

export const useLtbInputContext = () => {
  return useContext(LtbInputContext);
};

const INITIALSTATE = {
  matterId: undefined,
  ltbDeadlineUrl: '',
  selectedOptionClientMatter: null,
};

export const AddLtbInputProvider = ({ children }) => {
  const [ltbInputState, setLtbInputState] = useState(INITIALSTATE);

  return (
    <LtbInputContext.Provider
      value={{
        ltbInputState,
        setLtbInputState,
      }}
    >
      {children}
    </LtbInputContext.Provider>
  );
};
