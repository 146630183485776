
import timeEntryMutation from "./timeEntryMutation";

const timeEntryService = {
    addTimeEntry: async(timeEntryDto) => {
        return await timeEntryMutation.addTimeEntry(timeEntryDto);
    },
    postTimeEntry: async(timeEntryDto) => {
        return await timeEntryMutation.postTimeEntry(timeEntryDto);
    },
    savePostedTimeEntry: async(timeEntryDto) => {
        return await timeEntryMutation.savePostedTimeEntry(timeEntryDto);
    },
    deleteTimeEntry: async(timeEntryIds) => {
        return await timeEntryMutation.deleteTimeEntry(timeEntryIds);
    },
    updateMultipleTimeEntry: async(timeEntryDto) => {
        return await timeEntryMutation.updateMultipleTimeEntry(timeEntryDto);
    },
    updateTimeEntry: async(timeEntryDto) => {
        return await timeEntryMutation.addTimeEntry(timeEntryDto);
    },
    getRecentClientMatterList: async() => {
        return await timeEntryMutation.getRecentClientMatterList();
    },
    searchClientMatters: async(searctText) => {
        return await timeEntryMutation.searchClientMatters(searctText);
    },
    searchLocations: async(searchText, customerId) => {
        return await timeEntryMutation.searchLocations(searchText, customerId);
    },
    getTimeEntryById: async(timeEntryId) => {
        return await timeEntryMutation.getTimeEntryById(timeEntryId);
    },
    getAccountingPeriod: async(transactionDate) => {
        return await timeEntryMutation.getAccountingPeriod(transactionDate);
    },
    getEntryTypes: async() => {
        return await timeEntryMutation.getEntryTypes();
    },
    getTimeKeepers: async (searctText, customerId) => {
        return await timeEntryMutation.getTimeKeepers(searctText, customerId);
    },
    searchActivityCode: async(searchText, customerId) => {
        return await timeEntryMutation.searchActivityCode(searchText, customerId);
    },
    getTotalForTimeEntry: async(timeEntry) => {
        return await timeEntryMutation.getTotalForTimeEntry(timeEntry);
    },
    getRateByTaskCode: async(timeEntry) => {
        return await timeEntryMutation.getRateByTaskCode(timeEntry);
    },
    searchTaskCode: async(searchText,customerId) => {
        return await timeEntryMutation.searchTaskCode(searchText,customerId);
    },
    unpostTimeEntry: async(activityId) => {
        return await timeEntryMutation.unpostTimeEntry(activityId);
    },
}

export default timeEntryService;
