var INITIALSTATE = {
    activity: null,
    activityCodeId: null,
    activityId: null,
    activityDate: "",
    activityTime: "00:00:00",
    activityTypeId: null,
    adjustedDate: null,
    amount: "",
    billGenerate: null,
    billable: 0,
    budgetPhaseId: null,
    calendarEventId: null,
    clientId: 1,
    customerId: 1,
    description: null,
    doNotCharge: false,
    employeeId: null,
    flatFee: null,
    geoLocationId: null,
    geoLocationCode: null,
    geoLocationDescription: null,
    hours: 0,
    isAdjusted: null,
    modifiedById: 1,
    note: "",
    quantity: 0,
    rate: 0,
    taskCode: null,
    taskCodeId: null,
    timeElapsed: null,
    timeTotal: null,
    writeOff: false,
};
export default INITIALSTATE;