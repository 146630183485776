import { createSlice , createAsyncThunk} from '@reduxjs/toolkit';
import { screens } from '../../enums/screens';
import {literals} from "../../enums/literalCodes";
import {axiosInstance}  from '../../axiosInstance';
import config from '../../../config';
import axios from "axios";

const initialState = {
  headers: {h1:"",h2:""},
  page:screens.DASHBOARD,
  attorneyFirstName: "",
  allAutoCorrects: [],
  employeeId: null,
}

export const loggedInUser = createAsyncThunk('getUser', async () => {
 
  const userResponse = await axiosInstance.post(config.validateToken_URL,{ "appId": "admin" });
  let response = {data: userResponse.data, status: userResponse?.status}
  return response;
})

export const getAllAutoCorrects = createAsyncThunk('getAutoCorrects', async () => {
  const autoCorrectsMutation = `
    {
      getAllAutoCorrects{
          code
          description
          isCaseSensitive
          isPublic
          ownerId
      }
    }`

  //Creating Temporary instance because it has different Base URL.
  axios.defaults.baseURL = config.graphql_URL_TEMP
  axios.defaults.headers.sid = localStorage.getItem('sid');

  const response = await axios.post('', {
    query: autoCorrectsMutation,
    
  });
    return response?.data?.data;
})

export const UserSlice = createSlice({
  name: 'userState',
  initialState,
  reducers: {
  
  },
  extraReducers: builder => {
    builder
      .addCase(loggedInUser.fulfilled, (state, action) => {
        const firstName = action.payload.data.data.user_info.first_name;
        state.headers = {...state.headers, h1 : firstName  + "'s " + literals.DASHBOARD}
        state.attorneyFirstName = firstName,
        state.employeeId = action.payload.data.data.user_info.employee_id
      })
      .addCase(getAllAutoCorrects.fulfilled, (state, action) => {
        state.allAutoCorrects = action.payload.getAllAutoCorrects
      })
  }
})

export default UserSlice.reducer;