import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AddTimeEntry from '../AddTimeEntry/AddTimeEntry'

export default function TimeEntryModal({ activityDate, setHandlerTime, open, handleClose, selectedTimeEntry, isOpenFromModel, previousTime, clientMatter, customerId, isOpenFromRecentMattersModel, isOpenFromcalender = false, billableData,widgetId }) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{width: '800px', margin: 'auto'}}
        maxWidth={'md'}
      >
        <DialogContent style={{padding:0}}>
            <AddTimeEntry 
            billableData={billableData}
              handleClose={handleClose}
              selectedTimeEntry={selectedTimeEntry}
              isOpenFromModel={isOpenFromModel}
              setHandlerTime={setHandlerTime}
              selectedClientMatter={clientMatter}
              previousTime={previousTime} 
              customerId={customerId}
              isOpenFromRecentMattersModel={isOpenFromRecentMattersModel}
              activityDate={activityDate}
              isOpenFromcalender={isOpenFromcalender}
              widgetId={widgetId}
              />
        </DialogContent>
      </Dialog>
    </div>
  );
}