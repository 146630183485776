import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {axiosInstance} from "../../axiosInstance.js"

const initialState = { timerState: 'IDLE', activeTimer: {}, time:  localStorage.getItem("timer") ? parseInt(localStorage.getItem("timer")) : 0,}

export const handleTimerEvent = createAsyncThunk('timersEvent', async (timeEntryDto) => {
  const timerMutation = `
  mutation registerTimerEvent($TimerEventRequest: TimerEventRequest) {
    registerTimerEvent(timerEventRequest: $TimerEventRequest)
}`

  const timerResponse = await axiosInstance.post('', {
    query: timerMutation,
    variables: { TimerEventRequest: timeEntryDto },
  })
  
  let response = {data: timerResponse?.data?.data, status: timerResponse?.status}
  return response;
})

export const timerSlice = createSlice({
  name: 'timers',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(handleTimerEvent.fulfilled, (state, action) => {
        const timer = action.payload.data.timerEvent
        state.timerState = action.payload.status
        state.activeTimer = timer
      })
    }
})
export const {setTime, handleTimerEventsReducer} = timerSlice.actions;

export default timerSlice.reducer