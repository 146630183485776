import React, { memo } from "react"
import {
  Grid,
  Popper,
  Paper,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import "./TimeEntryDataGrid.scss"


const CustomToggleColumn = ({DataGridRef, columns, open, handleColumnVisibilityChange, anchorEl}) => {
    
    return (
        <Popper
            className='custom-column-toggle'
            open={open}
            placement="bottom"
            anchorEl={anchorEl}
            disablePortal={true}
            modifiers={[
                {
                    name: 'flip',
                    enabled: false,
                    options: {
                        altBoundary: false,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: false,
                        altBoundary: false,
                        tether: false,
                        rootBoundary: 'viewport',
                        padding: 8,
                    },
                },
                {
                    name: 'arrow',
                    enabled: false,
                },
            ]}
        >
            <Paper style={{ width: DataGridRef.current.clientWidth }}>
                <Grid container >
                    {columns.map((column, id) => (
                        <Grid lg={2} key={id}>
                            <FormControlLabel
                                key={column.field}
                                control={
                                    <Checkbox
                                        checked={column.isVisible}
                                        onChange={() => handleColumnVisibilityChange(column.field)}
                                    />
                                }
                                label={column.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Popper>
    )
}
  
export default memo(CustomToggleColumn) 