import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { createSlice } from '@reduxjs/toolkit';
import jsUtils from '../../utils/jsUtils';

dayjs.extend(utc);
dayjs.extend(timezone);
const initialState = {
  timers: {},
};


const timerControllerSlice = createSlice({
  name: 'timerController',
  initialState,
  reducers: {
    startTimer: (state, action) => {
      const { timerId, intervalId, time, component } = action.payload;

      let startTime = state.timers[timerId]?.startTime || Date.now(); // Use existing startTime or current time

      if (typeof (state.timers[timerId]) !== "undefined") {
        // If the timer was previously stopped, adjust startTime for time elapsed
        if (state.timers[timerId].timerStatus === "stopped") {
          startTime += Date.now() - state.timers[timerId].stopTime;
        }
      }
      state.timers[timerId] = {
        intervalId,
        startTime,
        time: time,
        isTracking: true,
        isStopped: false,
        timerStatus: "running",
        component: component
      };
    },
    stopTimer: (state, action) => {
      const { timerId } = action.payload;
      clearInterval(state.timers[timerId].intervalId);
      state.timers[timerId].isTracking = true;
      state.timers[timerId].isStopped = true;
      state.timers[timerId].timerStatus = "stopped";
      state.timers[timerId].stopTime = Date.now();
    },
    resetTimer: (state, action) => {
      const { timerId } = action.payload;
      if (state.timers[timerId]) {
        clearInterval(state.timers[timerId].intervalId);
        state.timers[timerId].startTime = 0;
        state.timers[timerId].time = 0;
        state.timers[timerId].isTracking = false;
        state.timers[timerId].isStopped = true;
        state.timers[timerId].timerStatus = "reset";
      }
    },
    updateTimer: (state, action) => {
      const { timerId } = action.payload;

      if (typeof (state.timers[timerId]) !== "undefined") {
        if (state.timers[timerId].timerStatus === "stopped") {
          state.timers[timerId].startTime += Date.now() - state.timers[timerId].stopTime;
          state.timers[timerId].isStopped = false;
        }
      }
      const currentTime = Date.now();
      const startTime = state.timers[timerId].startTime;
      const timeElapsed = Math.floor((currentTime - startTime) / 1000);
      state.timers[timerId].time = timeElapsed;
    },
    setGridStoppedTimer: (state, action) => {
      const { timerId, time, timerDTO } = action.payload;
      const userTimeZone = jsUtils.getUserTimezone();
      const startTime = dayjs(timerDTO.timerStartDateTime).tz(userTimeZone)?.valueOf();
      const stopTime = dayjs(timerDTO.timerStopDateTime).tz(userTimeZone)?.valueOf();
      state.timers[timerId] = {
        intervalId: '',
        time: time,
        startTime, // startTime from the previous started time.
        isTracking: true,
        isStopped: true,
        stopTime: stopTime, //stopTime from the previous started time.
        timerStatus: "stopped",
        component: "Grid"
      }
    },
    setGridStartedTimer: (state, action) => {
      const { timerId, time, timerDTO } = action.payload;
      
      const userTimeZone = jsUtils.getUserTimezone();
      const startTime = dayjs(timerDTO.timerStartDateTime).tz(userTimeZone)?.valueOf();

      state.timers[timerId] = {
        intervalId: '',
        time: time,
        startTime, // startTime from the previous started time.
        isTracking: true,
        isStopped: true,
        stopTime: null,
        timerStatus: "running",
        component: "Grid"
      }
    }
  },
});

export const { startTimer, stopTimer, resetTimer, updateTimer, setGridStoppedTimer, setGridStartedTimer } = timerControllerSlice.actions;

export default timerControllerSlice.reducer;
