import { gql } from "@apollo/client";

export const GET_TIME_ENTRIES_BY_DATE_RANGE = gql`
query getFeeCalendarDetails($feeCalendarRequest: FeeCalendarRequest) {
    getFeeCalendarDetails(feeCalendarRequest: $feeCalendarRequest) {
      feeCalDays {
        entryDate
        billableHours
        nonBillableHours
      }
      summary {
          count
          billableHours
          nonBillableHours
          totalHours
          totalAmount
      }
      timeEntriesByCM {
          clientCode
          clientDescription
          matterCode
          matterDescription
          totalHours
          totalAmount
          clientId
          customerId
      }    
    }
  }
`;
