import React, { memo } from "react"
import {
  Grid,
  Popper,
  Paper,
  MenuItem
} from '@mui/material';
import CssTextField from "../../../components/common/CssTextField.jsx";
import StyledButton from "../../../components/common/StyledButton"
import { timeEntryColumns } from "../../../enums/timeEntryColumns";
import { dataGridSearchOperators } from "../../../enums/dataGridSearchOperators";
import "./TimeEntryDataGrid.scss"

const CustomSearch = ({ setFilteredRows, open, anchorEl, rows, selectedColumn, selectedOperator, searchText, handleColumnChange, setClearFilters }) => {

  const handleValueChange = (event) => {
    const {name, value} = event.target
    handleColumnChange(name, value);
  }

  const clearFilter = () => {
    setClearFilters()
    setFilteredRows(rows);
  };

  const filterRows = () => {
    
    const filteredData = rows.filter((row) => {
      const columnValue = row[selectedColumn === "date" ? "activityDate" : selectedColumn];
      const operator = selectedOperator;
      const searchValue = searchText.toLowerCase();

      if (typeof columnValue === 'string') {
        const lowercaseColumnValue = columnValue.toLowerCase();

        switch (operator) {
          case 'contains':
            return lowercaseColumnValue.includes(searchValue);
          case 'isequal':
            return lowercaseColumnValue === searchValue;
          case 'startswith':
            return lowercaseColumnValue.startsWith(searchValue);
          case 'endswith':
            return lowercaseColumnValue.endsWith(searchValue);
          default:
            return true;
        }
      } else if (operator === 'isempty') {
                return !columnValue;
      } else if (operator === 'isnotempty') {
                return !!columnValue;
      }

      return false;
    });
    
    setFilteredRows(filteredData);
  };

  return (
    <Popper
      className='custom-column-search'
      open={open}
      placement="bottom"
      anchorEl={anchorEl}
      disablePortal={true}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
          options: {
            altBoundary: false,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: false,
            altBoundary: false,
            tether: false,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: false,
        },
      ]}
    >
      <Paper style={{ width: "385px" }}>
        <Grid container >
          <Grid item xs={12} sm={6} lg={6}>
            <CssTextField
              label="Column"
              fullWidth
              size="small"
              select
              name="column"
              InputLabelProps={{ shrink: true }}
              color="primary"
              value={selectedColumn}
              onChange={(value) => handleValueChange(value)}
              variant="standard"
              SelectProps={{
                MenuProps: {
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  style: {
                    maxHeight: '300px',
                  },
                },
              }}
            >
              {timeEntryColumns.map((column, index) =>
                <MenuItem key={index} value={column.field}>{column.name}</MenuItem>
              )}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <CssTextField
              label="Operator"
              fullWidth
              size="small"
              select
              name="operator"
              InputLabelProps={{ shrink: true }}
              color="primary"
              value={selectedOperator}
              onChange={(value) => handleValueChange(value)}
              variant="standard"
            >
              {dataGridSearchOperators.map((operators, index) =>
                <MenuItem key={index} value={operators.field}>{operators.name}</MenuItem>
              )}
            </CssTextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <CssTextField
              label="Value"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              color="primary"
              variant="standard"
              name="search"
              type="text"
              value={searchText}
              onChange={(value) => handleValueChange(value)}
            >
            </CssTextField>
          </Grid>
          <Grid item sm={12} lg={12}>
            <div className='custom-search-buttons'>
              <StyledButton onClick={() => clearFilter()}>Clear</StyledButton>
              <StyledButton onClick={() => filterRows()}>Search</StyledButton>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Popper>
  )
}

export default memo(CustomSearch) 