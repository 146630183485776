// AutocompleteProvider.js
import React, { createContext, useContext, useState } from 'react';

const AutocompleteContext = createContext();

export const AutocompleteProvider = ({ children }) => {
  const [shouldClear, setShouldClear] = useState(false);

  const clearAll = () => {
    setShouldClear(true);
    setTimeout(() => {
      setShouldClear(false);
    }, 100);
  };

  return (
    <AutocompleteContext.Provider value={{ shouldClear, clearAll }}>
      {children}
    </AutocompleteContext.Provider>
  );
};

export const useAutocomplete = () => {
  return useContext(AutocompleteContext);
};

export default AutocompleteProvider;
