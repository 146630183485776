import jsUtils from "../../../utils/jsUtils";

export let columnsDef = [
    {
      field: "time",
      headerName: "Timer",
    //   width: getTimerWidth(),
      updateRenderCell:true,
      updateRenderEditCell:true,
      columnRenderType:'timer',
      columnRenderEditType:'timer',
      editable: false,
      defaultWidth:150
    },
    {
      field: "clientMattersDescription",
      headerName: "Client/Matter",
      type: "string",
      updateRenderCell:true,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'autocomplete',
      editable: true,
      defaultWidth:150,
      sortComparator:(v1, v2) => {
        const dataV1 = v1?.trim()?.split(" ")?.[1];
        const dataV2 = v2?.trim()?.split(" ")?.[1];
        return jsUtils.columnSortingMethod(dataV1,dataV2);
      },
    },
    {
      field: "timekeeper",
      headerName: "Timekeeper",
      editable: true,
      updateRenderCell:true,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'autocompletecustom',
      defaultWidth:150,
    },
    {
      field: "type",
      headerName: "Type",
      defaultWidth:140,
      editable: true,
      updateRenderCell:true,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'dropdown',
    },
    {
      headerName: "Date",
      field: "date",
      editable: true,
      updateRenderCell:false,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'date',
      defaultWidth:160,
      renderCell: (params) => {
        return <div>{params?.row?.activityDate}</div>;
      },
    },
    {
      field: "hours",
      headerName: "Hours",
      type: "string",
      defaultWidth:110,
      editable: true,
      updateRenderCell:false,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'input',
    },
    {
      field: "taskCode",
      headerName: "Task",
      defaultWidth:150,
      editable: true,
      updateRenderCell:true,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'autocomplete',
    },
    {
      field: "activity",
      headerName: "Activity",
      defaultWidth:150,
      editable: true,
      updateRenderCell:true,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'autocomplete',
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      defaultWidth:110,
      editable: true,
      updateRenderCell:false,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'input',
    },
    {
      field: "rate",
      headerName: "Rate",
      type: "string",
      defaultWidth:110,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
      columnRenderType:'text',
      columnRenderEditType:'text',
      renderCell: (params) => {
        const formattedRate = `$${parseFloat(params.row.rate).toFixed(2)}`;
        return <span>{formattedRate}</span>;
      },
    },
    {
      field: "amount",
      headerName: "Total",
      defaultWidth:100,
      editable: true,
      updateRenderCell:false,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'input',
      renderCell: (params) => {
        const formattedAmount = `$${parseFloat(params.row.amount).toFixed(2)}`;
        return <span>{formattedAmount}</span>;
        
      },
    },
    {
      field: "status",
      headerName: "Status",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "location",
      headerName: "Location",
      defaultWidth:140,
      editable: true,
      updateRenderCell:true,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'autocomplete',
    },
    {
      field: "phase",
      headerName: "Phase",
      defaultWidth:160,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "description",
      headerName: "Narrative",
      type: "string",
      editable: true,
      updateRenderCell:true,
      updateRenderEditCell:true,
      columnRenderType:'text',
      columnRenderEditType:'input',
      defaultWidth:200
    },
    {
      field: "note",
      headerName: "Note",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "billable",
      headerName: "Billable",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "transactionDate",
      headerName: "Transaction Date",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "postedBy",
      headerName: "Posted By",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "service",
      headerName: "Service",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "activityDate",
      headerName: "Entry Date",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },

    {
      field: "startTime",
      headerName: "Start Time",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },

    {
      field: "createdBy",
      headerName: "Created By",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "entryId",
      headerName: "Entry ID",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
    },

    {
      field: "taxExempt",
      headerName: "Tax Exempt",
      defaultWidth:140,
      editable: false,
      updateRenderCell:false,
      updateRenderEditCell:false,
      renderCell: (params) => {
        return <span>{params.row.taxExempt ? "Yes" : "No"}</span>;
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      defaultWidth:140,
      cellClassName: "actions",
      editable:true,
      updateRenderCell:false,
      updateRenderEditCell:false,
      columnRenderType:'action',
      columnRenderEditType:'actions',
    },
  ];