import React, {useState, useEffect} from 'react';
import {
    IconButton,
    Box
} from '@mui/material';
import {
    DataGridPro,
    GridActionsCellItem,
    GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import "./ExpenseEntryDataGrid.scss"
import expenseEntryService from "../GraphQL/Services.js";


import CustomPaginationTimeEntryGrid from '../../../components/CustomPagination/CustomPaginationTimeEntryGrid.jsx';
import AlertDialog from "../../../components/common/AlertDialog"
import { literals } from "../../../enums/literalCodes";
import { LOAD_ALL_ACTIVITY_EXPENSE } from '../GraphQL/Queries';
import { useQuery } from "@apollo/client";
import CustomGridToolbarContainer from "../../../components/common/CustomGridToolbarContainer";

LicenseInfo.setLicenseKey('48ec2ee37f2a8c7b5049fd7177b37db9Tz04NzcxOSxFPTE3NDM3MTIxODEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function EditToolbar(props) {
    const { removeWidgetFromDashboard, widgetId } = props;

    return (
        <CustomGridToolbarContainer
        title={literals.EXPENSE_ENTRY_GRID}
        secondary={
        <div className='toolbar-button-group'>
            <GridToolbarFilterButton
              className='filter-button'
              componentsProps={{
                button: {
                  startIcon: (
                    //<IconButton >
                        <SearchIcon style={{ fontSize: '1.5rem' }} />
                    //</IconButton>
                  ),
                },
              }}
            />
              <IconButton>
                <Tooltip title="Settings">
                  <SettingsIcon />
                </Tooltip>
              </IconButton>
              <Tooltip title={literals.REMOVE_WIDGET}>
                <IconButton 
                onClick={() => removeWidgetFromDashboard(widgetId)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
        </div>
        }
        >
        </CustomGridToolbarContainer>
    );
  }

EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func,
    setRows: PropTypes.func,
    handleCloseTimeEntryDataGrid: PropTypes.func,
    rowSelectionModel: PropTypes.array,
    rows: PropTypes.array,
};

export default function ExpenseEntryDataGrid({setOpen, setSelectedExpenseEntry, isLoading, GetExpenseEntryList, removeWidgetFromDashboard, widgetId }) {
    const rowModesModel = {};
    const [rows, setRows] = React.useState([]);
    const [Modal, setModal] = useState(null)

    const {loading,data} = useQuery(LOAD_ALL_ACTIVITY_EXPENSE);

    useEffect(() => {
      if(data){
          setRows(data.getAllActivityExpenses)
      }
    }, [data]);

    const columns = [
        {
            field: 'clientName',
            headerName: 'Client',
            type: 'string',
            // editable: true,
            width: 240,
            valueGetter: params => `${params.row.clientEntity.code} - ${params.row.clientEntity.description}`,
        },
        {
            field: 'matterName',
            headerName: 'Matter',
            type: 'string',
            width: 200,
            // editable: true,
            valueGetter: params => `${params.row.customerEntity.code} - ${params.row.customerEntity.description}`,
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 140,
            editable: false,
            renderCell: (params) => ( <div>
                {
                    <span>{ dayjs(params.row.date).format('MM/DD/YYYY')}</span>
                }
            </div>
                
            ),
        },
        {
            field: 'expense',
            headerName: 'Expense',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: params => `${params.row.activityExpense.expenseCode.code} - ${params.row.activityExpense.expenseCode.description}`,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 90,
            editable: false,
            valueGetter: params => params.row.activity.quantity
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 90,
            editable: false,
            valueGetter: params => `$${params.row.activity.amount}`
        },
        {
            field: 'narrative',
            headerName: 'Narrative',
            type: 'string',
            width: 150,
            // editable: true,
            valueGetter: params => params.row.activityExpense.description
        },
        {
            field: 'attachment',
            headerName: 'Attachment',
            type: 'string',
            width: 150,
            // editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 70,
            editable: false,
            valueGetter: params => params.row.activityStatus.code
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 140,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                const RowDetail = rows.find(row => row.id === id);
                const isDisabledForPosting = false;
                if (!isDisabledForPosting) {
                    return [
                        <GridActionsCellItem
                            key="Edit"
                            icon={<Tooltip title="Edit" color='primary'><EditIcon /></Tooltip>}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id, RowDetail)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            key="Delete"
                            icon={<Tooltip title="Delete" color='primary'><DeleteIcon /></Tooltip>}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [<div key="none"></div>];
            },
        },
    ];    


    const handleEditClick = (id, row) => () => {
        setOpen(true);
        setSelectedExpenseEntry(row)
    }; 

    const handleDeleteClick = (id) => () => {
      setModal({
          open: true,
          title: literals.DELETE_EXPENSE_ENTRY,
          message: literals.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_EXPENSE_ENTRY,
          cancelLabel: literals.GO_BACK,
          discardLabel: literals.DELETE,
          confirmLabel: "",
          rowId: id,
          onCancel: closeModal,
          onDiscard: deleteExpenseEntry,
          ModalType: literals.TWO_ACTIONS
      });
  };

    const closeModal = () => {
      setModal(null);
    }

    const deleteExpenseEntry = (rowId) => {
        expenseEntryService.deleteExpenseEntry(rowId)
        .then(res => {
            if(res?.data?.deleteActivityExpense){
                closeModal();
                GetExpenseEntryList();
            }
        })
        .catch(e => {
            console.log(e)
        });
    }
    
    return (
        <>
            <Box
                sx={{
                    height: "100%",
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                }}
                >
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    disableColumnMenu={true} // to disable column menu
                    checkboxSelection // enable checkbox selection
                    disableRowSelectionOnClick // disable row selection on click
                    localeText={{
                        // to remove/hide the default text of filter Icon
                        toolbarFilters: "",
                    }}
                    slots={{
                        //custom Toolbar
                        toolbar: EditToolbar,
                        loadingOverlay: LinearProgress,
                        pagination: CustomPaginationTimeEntryGrid
                    }}
                    slotProps={{
                        toolbar: {
                            removeWidgetFromDashboard,
                            widgetId,
                        },
                    }}
                    loading={loading}
                    pagination={true}
                    initialState={{
                        pagination: {
                        paginationModel: { pageSize: 100, page: 0 },
                        },
                    }}
                />
            </Box>
            {Modal !== null && <AlertDialog {...Modal} />}
        </>
    )
}
