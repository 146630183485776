import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const CustomTabs = styled(Tabs)(({selected}) => ({
  // Add more custom styles as needed...
  '& .Mui-selected':{
    backgroundColor: selected === true ? '#EAE6FA' : '#EAF4FF',
  },
}));

const CustomTab = styled(Tab)(() => ({}));

export { CustomTabs, CustomTab };
