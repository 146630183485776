import { gql } from "@apollo/client";

export const GET_RECENT_CLIENT_MATTER_LIST = gql`
  query {
    getRecentClientMatters{
        clientMatters{
                clientId
                clientCode
                clientDesc
                matterId
                matterCode
                matterDesc
                description
                billableType{
                    id
                    code
                }
                enforceBillableFlag
        }
    }
    
  }
`;
