import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {GridToolbarContainer} from '@mui/x-data-grid-pro';
// material-ui
import { Card, CardHeader, Typography, } from '@mui/material';

const CustomGridToolbarContainer = React.forwardRef(
    (
        {
            secondary,
            sx = {},
            title,
            ...others
        },
        ref
    ) => {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <GridToolbarContainer className='toolbar-container'>
            <Card
                ref={ref}
                {...others}
                sx={{
                    boxShadow: 'none',
                    width : "100%",
                    ...sx
                }}
            >
                {title && (
                    <CardHeader sx={headerStyle}
                        className={!isHovered ? 'allow_drag' : ""}
                        title={<div >
                            <Typography
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={styleTypography}
                            >
                                {title}
                            </Typography>
                        </div>
                        }
                        action={<div
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            {secondary}
                        </div>
                        }
                    >
                    </CardHeader>
                )}
            </Card>
        </GridToolbarContainer>

        );
    }
);

const styleTypography = {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.14px",
    width: "fit-content",
}

const headerStyle = {
    backgroundColor: "white",
    paddingBottom: '3px',
    paddingTop: '9px'
}

CustomGridToolbarContainer.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    button: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

CustomGridToolbarContainer.displayName = "CustomGridToolbarContainer";

export default CustomGridToolbarContainer;
