
import TimeEntrySS from "../../assets/images/TimeEntryWidget.jpg";
import TimeEntryGrid from "../../assets/images/Time_Entry_Data_Grid.jpg";
import RecentClients from "../../assets/images/Recent_Clients.jpg";
import FeeCalendar from "../../assets/images/FeeCalendar.jpg";

import {literals} from "../../enums/literalCodes";

const timeEntryWidgetMetadata = [
    {
        image: TimeEntrySS,
        title: literals.TIME_ENTRY,
        description: literals.TIME_ENTRY_CARD_DISCRIPTION,
        type: literals.TIME_ENTRY
    },
    {
        image: RecentClients,
        title: literals.RECENT_CLIENT,
        description: literals.RECENT_CLIENT_DESCRIPTION,
        type: literals.RECENT_CLIENT,
    },
    {
        image: TimeEntryGrid,
        title: literals.TIME_ENTRY_GRID,
        description: literals.TIME_ENTRY_GRID_DESCRIPTION,
        type: literals.TIME_ENTRY_GRID
    },
    {
        image: FeeCalendar,
        title: literals.FEE_CALENDAR,
        description: literals.FEE_CALENDAR_DESCRIPTION,
        type: literals.FEE_CALENDAR
    },
];
export default timeEntryWidgetMetadata;
