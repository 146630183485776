import { gql } from '@apollo/client';

export const GET_TIME_ENTRIES_BY_MONTH = gql`
mutation search($timeEntrySearchRequest: TimeEntrySearchRequest) {
  search (
      timeEntrySearchRequest: $timeEntrySearchRequest) {
      totalPages,
      totalElements,
      size,
      number,
      content {
          activityId
          narrative
          note
          activityTime
          activityDate
          activityCode
          activityDescription
          taskCode
          budgetJobPhaseId
          statusId
          billable
          createdDate
          modifiedDate
          activityDate
          hours
          amount
          activityTypeCode
          activityTypeDescription
          geoLocationCode
          geoLocationDescription           
          customerId
          matterCode
          matterDescription
          clientCode
          clientDescription
          createdBy {
              employeeId
              firstName
              middleInitial
              lastName
              employeeType {
                  id
                  code
                  description
              }
          }
          modifiedBy {
              employeeId
              firstName
              middleInitial
              lastName
              employeeType {
                  id
                  code
                  description
              }
          }
          timekeeper {
              employeeId
              firstName
              middleInitial
              lastName
              employeeType {
                  id
                  code
                  description
              }
          }
          timerDto {
              timerId
              activityId
              createdById
              createdDate
              modifiedById
              modifiedDate
              timerStartDateTime
              timerStopDateTime
              timeElapsed
          }
      }
  }
}
`;

export const GET_TIME_ENTRIES_BY_DATE = gql`
mutation search($timeEntrySearchRequest: TimeEntrySearchRequest) {
    search (
        timeEntrySearchRequest: $timeEntrySearchRequest) {
        totalPages,
        totalElements,
        size,
        number,
        content {
            activityId
            narrative
            note
            activityTime
            activityDate
            activityCode
            activityDescription
            taskCode
            budgetJobPhaseId
            statusId
            billable
            createdDate
            modifiedDate
            activityDate
            hours
            amount
            activityTypeCode
            activityTypeDescription
            geoLocationCode
            geoLocationDescription           
            customerId
            matterCode
            matterDescription
            clientCode
            clientDescription
            createdBy {
                employeeId
                firstName
                middleInitial
                lastName
                employeeType {
                    id
                    code
                    description
                }
            }
            modifiedBy {
                employeeId
                firstName
                middleInitial
                lastName
                employeeType {
                    id
                    code
                    description
                }
            }
            timekeeper {
                employeeId
                firstName
                middleInitial
                lastName
                employeeType {
                    id
                    code
                    description
                }
            }
            timerDto {
                timerId
                activityId
                createdById
                createdDate
                modifiedById
                modifiedDate
                timerStartDateTime
                timerStopDateTime
                timeElapsed
            }
        }
    }
}
`

export const GET_TIME_ENTRIES_BY_CM = gql`
mutation search($timeEntrySearchRequest: TimeEntrySearchRequest) {
    search (
        timeEntrySearchRequest: $timeEntrySearchRequest) {
        totalPages,
        totalElements,
        size,
        number,
        content {
            activityId
            narrative
            note
            activityTime
            activityCode
            activityDescription
            taskCode
            budgetJobPhaseId
            statusId
            billable
            createdDate
            modifiedDate
            activityDate
            hours
            amount
            activityTypeCode
            activityTypeDescription
            geoLocationCode
            geoLocationDescription
            matterCode
            matterDescription
            clientCode
            clientDescription
            createdBy {
                employeeId
                firstName
                middleInitial
                lastName
                employeeType {
                    id
                    code
                    description
                }
            }
            modifiedBy {
                employeeId
                firstName
                middleInitial
                lastName
                employeeType {
                    id
                    code
                    description
                }
            }
            timekeeper {
                employeeId
                firstName
                middleInitial
                lastName
                employeeType {
                    id
                    code
                    description
                }
            }
            timerDto {
                timerId
                activityId
                createdById
                createdDate
                modifiedById
                modifiedDate
                timerStartDateTime
                timerStopDateTime
                timeElapsed
            }
        }
    }
}
`

export const POST_TIME_ENTRIES = gql`
mutation PostAllCalendarEntries($req: FeeCalendarRequest) {
    postAllCalendarEntries(feeCalendarRequest: $req) {
		results {
			complianceMessages {
				field
				errorLevel
				message
				regex
				canHighlightErrors
				isRegexCaseSensitive
			}
			procedureMessage {
				errorCode,
				sqlState
				message
			}
			timeEntryDto {
				activityId
				narrative
				note
				activityTime
				activityCode
				activityDescription
				taskCode
				budgetJobPhaseId
				statusId
				billable
				createdDate
				modifiedDate
				activityDate
				hours
				amount
				activityTypeCode
				activityTypeDescription
				geoLocationCode
				geoLocationDescription
				matterCode
				matterDescription
				clientCode
				clientDescription
				createdBy {
					employeeId
					firstName
					middleInitial
					lastName
					employeeType {
						id
						code
						description
					}
				}
				modifiedBy {
					employeeId
					firstName
					middleInitial
					lastName
					employeeType {
						id
						code
						description
					}
				}
				timekeeper {
					employeeId
					firstName
					middleInitial
					lastName
					employeeType {
						id
						code
						description
					}
				}
			}
		}
    }
}
`
