import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import AutoComplete from '../../../components/common/AutoComplete';
import StyledButton from '../../../components/common/StyledButton';
import MainCard from '../../../components/common/MainCard.jsx';
import './LTBInputWindow.scss';
import ltbService from '../GraphQL/Services';
import { literals } from '../../../enums/literalCodes.js';
import { openSnackbar } from '../../../redux-toolkit/slices/snackbar';
import { useDispatch } from 'react-redux';
import { ltbRequestModel } from '../Model/ltbRequestModel.js';
import jsUtils from '../../../utils/jsUtils.js';
import { useLtbInputContext } from '../../Dashboard/useLtbInputContext.jsx';

const LTBInputWindow = ({
  isOpenFromModel,
  selectedExpenseEntry,
}) => {
  const { ltbInputState, setLtbInputState } = useLtbInputContext();
  const [state, setState] = useState(() => {
    const initialState = {
      ...ltbInputState,
      ...(isOpenFromModel ? selectedExpenseEntry : {}),
    };
    if (isOpenFromModel && selectedExpenseEntry?.customer_id) {
      initialState.selectedOptionClientMatter = {
        id: selectedExpenseEntry.customer_id,
        code: selectedExpenseEntry.customer_code,
        description: selectedExpenseEntry.customer_description,
      };
    }
    return initialState;
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setLtbInputState(state);
  }, [state, setLtbInputState]);

  const handleOptionChange = (newOption) => {
    setState((prevState) => ({
      ...prevState,
      selectedOptionClientMatter: newOption,
    }));
  };

  const OptionDescription = (option) =>
    (option?.code ? option.code + ' - ' : '') +
    (option.description || '');

  const setOptionsChanges = (options, field) => {
    if (options) {
      if(field === 'customer_id'){
        let { matterId } = options;
          setState((prevState) => ({
            ...prevState,
            matterId: parseInt(matterId),
          }));
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [field]: null,
      }));
    }
  };

  const onDataSubmit = async () => {
    const ObjectModel = jsUtils.mapObjectToModel(
      ltbRequestModel,
      state
    );

    try {
      const { data } = await ltbService.getLtbDeadlineUrl(
        ObjectModel
      );
      handleResponse(data);
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        ltbDeadlineUrl: '',
      }));
      handleCatch(e);
    }
  };

  const createSnackbar = (successMessage) => {
    dispatch(
      openSnackbar({
        message: successMessage,
        severity: literals.SUCCESS,
      })
    );
  };

  const handleResponse = (response) => {
    setState((prevState) => ({
      ...prevState,
      ltbDeadlineUrl: response.getLtbDeadlineUrl.ltbDeadlineUrl,
    }));
    createSnackbar(literals.LTB_DEADLINE_URL_FETCHED);
  };

  const handleCatch = (e) => {
    dispatch(
      openSnackbar({
        message: literals.SOMETHING_WENT_WRONG,
        severity: literals.ERROR,
      })
    );
    console.error(e);
  };

  return (
    <div className="lbt-main-div">
      <MainCard className="time-entry-main-card" title={literals.LTB}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ display: 'flex', gap: '20px' }}
          >
            <AutoComplete
              setOptionsChanges={setOptionsChanges}
              state={state}
              errors={errors}
              selectedOption={state.selectedOptionClientMatter}
              setSelectedOption={handleOptionChange}
              searchSuggestions={ltbService.searchClientMatters}
              getOptionLabel={OptionDescription}
              field={'customer_id'}
              label={'Client/Matter'}
              disabled={false}
            />
            <StyledButton
              className="view-deadlines"
              onClick={onDataSubmit}
              variant="contained"
            >
              {literals.VIEW_DEADLINES}
            </StyledButton>
          </Grid>
        </Grid>
      </MainCard>

      {state.ltbDeadlineUrl && (
        <MainCard
          className="ltb-main-card"
          title={literals.LTB_DEADLINES}
        >
          <iframe
            key={state.ltbDeadlineUrl}
            src={state.ltbDeadlineUrl}
          ></iframe>
        </MainCard>
      )}
    </div>
  );
};

export default LTBInputWindow;
