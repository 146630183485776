import React, { memo, forwardRef, useRef, useState, useEffect } from 'react';
import CssMultiTextField from '../../components/common/CssMultiTextField.jsx';
import StyledTooltip from '../../components/common/StyledTooltip';
import InputAdornment from '@mui/material/InputAdornment';
import ContentEditable from 'react-contenteditable';
import jsUtils from '../../utils/jsUtils.js';
import { useDispatch, useSelector } from "react-redux";
import {
  ToggleTimeEntryData
} from "../../redux-toolkit/slices/DashboardTabs.js";

const KEY_STROKES = [190 , 32 , 188];

const CustomInputComponent = forwardRef((props, ref) => {
  const { value, setLastKeyPressed, ...otherProps } = props;

  const handleKeyDown = (event) => {
    setLastKeyPressed(event.keyCode);
    if ((event.ctrlKey || event.metaKey) && (event.key === 'b' || event.key === 'u')) {
      event.preventDefault();
    }
  };

  return (
    <ContentEditable innerRef={ref} html={value} onKeyDown={handleKeyDown} {...otherProps} />
  );
});
CustomInputComponent.displayName = 'CustomInputComponent';

const ComplianceTextField = ({
  state,
  setState,
  errors,
  setErrors,
  disabled = false,
  dictionary,
  inputRefTextField,
  field,
  isRequired,
  label,
  maxLength,
  helperText,
  testId
}) => {
  const inputRef = useRef(null);
  const [lastKeyPressed, setLastKeyPressed] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [isEditorEnabled, setisEditorEnabled] = useState(false);
  const [isPasted, setisPasted] = useState(false);
  const { TimeEntryData } = useSelector((state) => state.tab);

  const dispatch = useDispatch();

  //handling onPaste, 
  //if user pasted something into the field it would be true 
  //will call the  autocorrect function.
  const handlePaste = () => {
    setisPasted(true);
  };

  const getCursorPosition = (currentRef) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(currentRef);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange.toString().length;
    }
    return 0; // Cursor is at the beginning if nothing is selected
  }

  const handleChange = (event) => {
    const { name , value } = event.target;
    const decodedValue = jsUtils.decodeHtmlEntities(value);
    
    if (decodedValue.length <= maxLength) {

      let autoCorrectedValue = (isPasted || KEY_STROKES.includes(lastKeyPressed))
      ? jsUtils.getAutoCorrectedText(decodedValue, dictionary)
      : decodedValue;

      setState({
        ...state,
        [field]: autoCorrectedValue,
      });

      dispatch(ToggleTimeEntryData({...TimeEntryData, [name] : autoCorrectedValue}))

      if (errors[field]) {
        //if we had errors, will get the selector postion of ContentEditable and will assign it to the CssMultiTextField.
        setCursorPosition(getCursorPosition(inputRef.current))
        setisEditorEnabled(true);
      }

      setisPasted(false);
      setErrors({ ...errors, [field]: null });
    }
  };

  useEffect(() => {
    if (isEditorEnabled) {
      inputRefTextField.current.focus();
      inputRefTextField.current.selectionStart = cursorPosition;
      setisEditorEnabled(false);
    }
  }, [errors[field]]);

  useEffect(() => {
    if (isEditorEnabled) {
      inputRefTextField.current.focus();
      inputRefTextField.current.selectionStart = cursorPosition;
      setisEditorEnabled(false);
    }
  }, [errors[field]]);

  const handleKeyDown = (event) => {
    setLastKeyPressed(event.keyCode);

    if ((event.ctrlKey || event.metaKey) && (event.key === 'b' || event.key === 'u')) {
      event.preventDefault();
    }
  };

  // ContentEditable and InputAdornment are conditional.
  //Will use CssMultiTextField as normal input.
  //will use ContentEditable in the case of compliance error.
  const inputProps = errors[field]
    ? {
        inputComponent: CustomInputComponent,
        inputProps: {
          value: jsUtils.encodeHtmlEntities(state[field]),
          onChange: handleChange,
          onKeyDown: handleKeyDown,
          setLastKeyPressed: setLastKeyPressed,
          ref: inputRef,
          style: { height: '2.9rem', overflow: 'auto' },
        },
        endAdornment: (
          <>
          {!!errors[field] && (
            <InputAdornment position="end">
            <StyledTooltip 
            title={errors[field].message} 
            severity={errors[field].errorLevel} 
            placement="left" arrow />
          </InputAdornment>
          )}
          </>
        ),
      }
    : {};

  return (
    <CssMultiTextField
      label={label}
      fullWidth
      required={isRequired}
      color={jsUtils.getFieldSeverity(errors[field])}
      name={field}
      autoComplete="off"
      InputLabelProps={{ shrink: true }}
      rows={2}
      inputProps={{'data-testid':testId?testId:'text'}}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      multiline
      error={!!errors[field]}
      className="narrative"
      inputRef={inputRefTextField}
      value={state[field]}
      InputProps={inputProps}
      disabled={disabled}
      helperText={helperText}
    />
  );
};

export default memo(ComplianceTextField);
