
import ExpenseEntry from "../../../assets/images/ExpenseEntry.jpg";
import ExpenseGrid from "../../../assets/images/ExpenseGrid.jpg";
import { literals } from "../../../enums/literalCodes";

const expenseWidgetMetadata = [
    {
        image: ExpenseEntry,
        title: literals.EXPENSE_ENTRY,
        description: literals.EXPENSE_ENTRY,
        type: literals.EXPENSE_ENTRY
    },       
    {
        image: ExpenseGrid,
        title: literals.EXPENSE_ENTRY_GRID,
        description: literals.EXPENSE_ENTRY_GRID,
        type: literals.EXPENSE_ENTRY_GRID
    },
];

export default expenseWidgetMetadata;
