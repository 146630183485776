import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete } from '@mui/material';
import CssTextField from './CssTextField.jsx';
import InputAdornment from '@mui/material/InputAdornment';
import StyledTooltip from './StyledTooltip.jsx';
import { ToggleTimeEntryData } from '../../redux-toolkit/slices/DashboardTabs.js';
import jsUtils from '../../utils/jsUtils.js';
import { useAutocomplete } from './AutocompleteProvider';

const AutoComplete = ({
  state,
  errors,
  selectedOption,
  setSelectedOption,
  searchSuggestions,
  getOptionLabel,
  setOptionsChanges,
  field,
  label,
  disabled = false,
  searchId,
  testId,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const { shouldClear } = useAutocomplete();
  const { TimeEntryData } = useSelector((state) => state.tab);

  const dispatch = useDispatch();

  const handleInputChange = (event, newInputValue) => {
    setOpenSuggestions(true);
    setInputValue(newInputValue);
    searchSuggestions(newInputValue, searchId, state)
      .then((data) => {
        setSuggestions(data);
      })
      .catch((e) => {
        setSuggestions([]);
        console.error(e);
      });
  };

  const handleOptionChange = (event, SelectedOptions) => {
    setSelectedOption(SelectedOptions);
    setOptionsChanges(SelectedOptions, field, state);
    setSuggestions([]);
    dispatch(
      ToggleTimeEntryData({
        ...TimeEntryData,
        [field]: SelectedOptions,
      })
    );
  };

  const handleKeyDown = (event) => {
    if (
      event.key === 'Tab' &&
      suggestions.length === 1 &&
      field !== 'customerId'
    ) {
      event.defaultMuiPrevented = true;
      const selected = suggestions[0];
      setSelectedOption(selected);
      setOptionsChanges(selected, field, state);
      dispatch(
        ToggleTimeEntryData({ ...TimeEntryData, [field]: selected })
      );
    }
  };

  useEffect(() => {
    if (state[field] === null) {
      setInputValue('');
      setSelectedOption('');
      setSuggestions([]);
    }
  }, [state[field]]);

  return (
    <Autocomplete
      options={suggestions}
      getOptionLabel={getOptionLabel}
      value={shouldClear ? null : selectedOption}
      onBlur={() => setOpenSuggestions(false)}
      onChange={handleOptionChange}
      onFocus={() => handleInputChange(null, '')}
      onKeyDown={handleKeyDown}
      open={openSuggestions}
      className="autocomplete"
      freeSolo
      disabled={disabled}
      style={{ width: '100%' }}
      disableClearable={inputValue ? false : true}
      // ListboxProps={{ 'data-testid': testId }}
      renderInput={(params) => (
        <CssTextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          data-testid={testId}
          color={jsUtils.getFieldSeverity(errors[field])}
          // value={inputValue}
          value={shouldClear ? null : inputValue}
          error={errors[field]}
          InputLabelProps={{
            shrink:
              field == 'matterName' || field == 'clientName'
                ? false
                : true,
          }}
          InputProps={{
            ...params.InputProps,
            tabIndex: -1,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {!!errors[field] && (
                  <InputAdornment position="end">
                    <StyledTooltip
                      title={errors[field].message}
                      severity={errors[field].errorLevel}
                      placement="left"
                      arrow
                    />
                  </InputAdornment>
                )}
              </>
            ),
          }}
          onChange={(event) =>
            handleInputChange(event, event.target.value)
          }
        />
      )}
    />
  );
};

export default memo(AutoComplete);
