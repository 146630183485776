import * as yup from "yup";
import { literals } from "../enums/literalCodes";

export const TimeEntryPostSchema = yup.object().shape({
  customerId: yup.string().required(literals.A_CLIENT_MATTER_MUST_BE_PROVIDED),
  employeeId: yup.string().required(literals.A_TIMEKEEPER_MUST_BE_PROVIDED),
  hours: yup
    .string()
    .test("is-not-dot-only", "Hours must be valid.", (value) => {
      return value !== ".";
    })
    .required(literals.HOURS_MUST_BE_PROVIDED),
  description: yup.string().required(literals.A_NARRATIVE_MUST_BE_PROVIDED),
  activityType: yup.string(),
  taskCodeId: yup.string().nullable().notRequired().when("activityType", {
    is: "TaskFee",
    then: (s) => s.required(literals.A_TASK_MUST_BE_PROVIDED),
    otherwise: (s) => s
  }),
  quantity: yup.string().nullable().notRequired().when("activityType", {
    is: "TaskFee",
    then: (s) => s.required(literals.A_QUANTITY_MUST_BE_PROVIDED),
    otherwise: (s) => s
  }),
  activityDate: yup
    .date()
    .typeError(literals.A_DATE_WITHIN_THE_NEXT_30_DAYS_MUST_BE_PROVIDED_IN_MMDDYYYY_FORMAT)
    .required(literals.THIS_IS_A_REQUIRED_FIELD)
    .test("valid-date", "Invalid date format. Use MM/DD/YYYY", (value) => {
      if (value === null || value === undefined || typeof value === "undefined") {
        return true;
      }
      // Convert the Date object to a string with the format MM/DD/YYYY
      const dateString = value.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      // Ensure that the date is in the format MM/DD/YYYY
      const regex = /^((0[1-9])|(1[0-2]))\/((0[1-9])|([12]\d)|(3[01]))\/\d{4}$/;
      return regex.test(dateString);
    }),
});

export const TimeEntrySaveSchema = yup.object().shape({
  customerId: yup.string().notOneOf(["", null], literals.A_CLIENT_MATTER_MUST_BE_PROVIDED),
  employeeId: yup.string().required(literals.A_TIMEKEEPER_MUST_BE_PROVIDED),
  date: yup
    .date()
    .typeError(literals.A_DATE_WITHIN_THE_NEXT_30_DAYS_MUST_BE_PROVIDED_IN_MMDDYYYY_FORMAT)
    .required(literals.THIS_IS_A_REQUIRED_FIELD)
    .test("valid-date", "Invalid date format. Use MM/DD/YYYY", (value) => {
      if (value === null || value === undefined || typeof value === "undefined") {
        return true;
      }
      // Convert the Date object to a string with the format MM/DD/YYYY
      const dateString = value.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      // Ensure that the date is in the format MM/DD/YYYY
      const regex = /^((0[1-9])|(1[0-2]))\/((0[1-9])|([12]\d)|(3[01]))\/\d{4}$/;
      return regex.test(dateString);
    }),
});

export const ExpenseEntrySaveSchema = yup.object().shape({
  customerId: yup.string().notOneOf(["", null], literals.A_CLIENT_MATTER_MUST_BE_PROVIDED),
  employeeId: yup.string().required(literals.A_TIMEKEEPER_MUST_BE_PROVIDED),
  geoLocationId: yup.string().required(literals.LOCATION_MUST_BE_PROVIDED),
  expenseCodeId: yup.string().required(literals.EXPENSE_CODE_MUST_BE_PROVIDED),
  date: yup
    .date()
    .typeError(literals.A_DATE_WITHIN_THE_NEXT_30_DAYS_MUST_BE_PROVIDED_IN_MMDDYYYY_FORMAT)
    .required(literals.THIS_IS_A_REQUIRED_FIELD)
    .test("valid-date", "Invalid date format. Use MM/DD/YYYY", (value) => {
      if (value === null || value === undefined || typeof value === "undefined") {
        return true;
      }
      // Convert the Date object to a string with the format MM/DD/YYYY
      const dateString = value.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      // Ensure that the date is in the format MM/DD/YYYY
      const regex = /^((0[1-9])|(1[0-2]))\/((0[1-9])|([12]\d)|(3[01]))\/\d{4}$/;
      return regex.test(dateString);
    }),
});

export const ExpenseEntryPostSchema = yup.object().shape({
  customerId: yup.string().required(literals.A_CLIENT_MATTER_MUST_BE_PROVIDED),
  employeeId: yup.string().required(literals.A_TIMEKEEPER_MUST_BE_PROVIDED),
  geoLocationId: yup.string().required(literals.LOCATION_MUST_BE_PROVIDED),
  expenseCodeId: yup.string().required(literals.EXPENSE_CODE_MUST_BE_PROVIDED),
  description: yup.string().required(literals.A_NARRATIVE_MUST_BE_PROVIDED),
  date: yup
    .date()
    .typeError(literals.A_DATE_WITHIN_THE_NEXT_30_DAYS_MUST_BE_PROVIDED_IN_MMDDYYYY_FORMAT)
    .required(literals.THIS_IS_A_REQUIRED_FIELD)
    .test("valid-date", "Invalid date format. Use MM/DD/YYYY", (value) => {
      if (value === null || value === undefined || typeof value === "undefined") {
        return true;
      }
      // Convert the Date object to a string with the format MM/DD/YYYY
      const dateString = value.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      // Ensure that the date is in the format MM/DD/YYYY
      const regex = /^((0[1-9])|(1[0-2]))\/((0[1-9])|([12]\d)|(3[01]))\/\d{4}$/;
      return regex.test(dateString);
    }),
});

export const DashboardStateSchema = yup.object().shape({
    model: yup.object().shape({
        tabs: yup.array().of(yup.object().shape({
          // id: yup.number().required('tab id is missing'),
          // isWidgetAllowed:yup.boolean().required('isWidgetAllowed missing'),
          widgets:yup.array().of(yup.object().shape({
            type:yup.string(),
            id:yup.number().required('widget id is missing'),
            layout:yup.object().shape({
              i: yup.string().required('layout component name is missing'),
              x: yup.number().required(), 
              y: yup.number().required(), 
              w: yup.number().required(), 
              minW: yup.number().required(), 
              h: yup.number().required(), 
              minH: yup.number().required(), 
              maxH: yup.number().required(), 
              isResizable: yup.boolean().required(), 
              static: yup.boolean().required()
            }).required('No widget layout')
          }))

        }))

      })
    })
          

