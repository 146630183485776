import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import timeEntryReducer from './slices/timeEntries'
import timerReducer from './slices/timerSlice'
import timerControllerSlice from './slices/timer'
import snackbarSlice from './slices/snackbar'
import tabSlice from "./slices/DashboardTabs";

export const store = configureStore({
  reducer: {
    userState: userReducer,
    timeEntry : timeEntryReducer,
    timer: timerReducer, 
    snackbar : snackbarSlice,
    tab : tabSlice,
    timerController : timerControllerSlice,
  },
  middleware: (getDefaultMiddleware) =>  getDefaultMiddleware({
    
    serializableCheck: false,
  }),
})