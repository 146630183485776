//a context to store the state of a form and a function to update the state, specific to the addTimeEntry compont
import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';
import { literals } from '../../enums/literalCodes';
const AddTimeEntryContext = createContext();

export const useAddTimeEntryContext = () => {
    return useContext(AddTimeEntryContext);
}

const INITIALSTATE = {
    clientMatter: "",
    activityId: null,
    activityTypeId: 15,
    activityDate: dayjs(new Date()).format("MM/DD/YYYY"),
    date: dayjs(new Date()),
    description: "",
    note: "",
    writeOff: false,
    doNotCharge: false,
    hours: "",
    isPost: true,
    amount: "",
    isNarrativeError: false,
    activityTime: "00:00:00",
    quantity: "",
    file: null,
    status: literals.DRAFT,
    customerId: null,
    activityType: "TimeFee",
    geoLocationId: null,
    activityCodeId: null,
    employeeId: null,
    taskCodeId: null,
    billable: 0,
    billableCode: "",
    enforceBillableFlag: true,
    taxExempt: null,
    rate: 0
  };

export const AddTimeEntryProvider = ({ children}) => {
    const [addTimeEntryState, setAddTimeEntryState] = useState(INITIALSTATE);
    const [prevAddTimeEntryState, setPrevAddTimeEntryState] = useState(INITIALSTATE);
    return (
        <AddTimeEntryContext.Provider value={{ addTimeEntryState, setAddTimeEntryState, prevAddTimeEntryState, setPrevAddTimeEntryState }}>
            {children}
        </AddTimeEntryContext.Provider>
    )
}
    