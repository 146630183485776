import axios from 'axios';
import config from "../config"

export const axiosInstance = axios.create({
  baseURL: config.graphql_URL,
  headers: {
    'sid': localStorage.getItem('sid'),
    'Content-Type': 'application/json',
  },
});

