import expenseRequestMutation from "./Mutations";

const expenseEntryService = {
  saveExpenseEntry: async (input) => {
    return await expenseRequestMutation.saveExpenseEntry(input);
  },
  postExpenseEntry: async (input) => {
    return await expenseRequestMutation.postExpenseEntry(input);
  },
  deleteExpenseEntry: async (expenseEntryId) => {
    return await expenseRequestMutation.deleteExpenseEntry(expenseEntryId);
  },
  searchClientMatters: async (searctText) => {
    return await expenseRequestMutation.searchClientMatters(searctText);
  },
  getTimeKeepers: async (searctText, customerId) => {
    return await expenseRequestMutation.getTimeKeepers(searctText, customerId);
  },
};

export default expenseEntryService;
