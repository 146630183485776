import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';

const UnStyledDatePicker = styled(DatePicker)({
  
  "& .MuiInputBase-root": {
    letterSpacing:'-0.65px',
    padding: 2,
    "& .MuiButtonBase-root": {
      padding: 0,
      paddingRight: 5
    },
    "& .MuiInputBase-input": {
      padding: 15,
      paddingLeft: 0
    }
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 'none'
  }
});

export default UnStyledDatePicker;
