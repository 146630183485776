import axios from "axios";
import config from "../../../../config";

const axiosInstance = axios.create({
  baseURL: `${config.baseUrl}${"/expense-management/graphql"}`,
  headers: {
    "Content-Type": "application/json",
    sid: localStorage.getItem("sid"),
  },
});

const ltbRequestMutation = {
  getLtbDeadlineUrl: async (input) => {
    const mutation = `
    mutation ($LtbRequestDto: LtbRequestDto!) {
      getLtbDeadlineUrl(ltbRequestDto: $LtbRequestDto) {
        ltbDeadlineUrl
      }
    }
    `;

    axios.defaults.baseURL = config.graphql_URL_TEMP;
    axios.defaults.headers.sid = localStorage.getItem("sid");

    const response = await axios.post("", {
      query: mutation,
      variables: { LtbRequestDto: input },
    });

    return response.data;
  },
  searchClientMatters: async (searchText) => {
    const mutationQuery = `
      query CreateClientMatter($searchText: String!) {
        searchClientMattersImpl(searchText: $searchText) {
          clientMatters {
            clientId
            clientCode
            clientDesc
            matterId
            matterCode
            matterDesc
            description
            billableType {
              id
              code
            }
            enforceBillableFlag
          }
        }
      }
    `;

    //Creating Temporary instance because it has different Base URL.
    axios.defaults.baseURL = config.graphql_URL_TEMP;
    axios.defaults.headers.sid = localStorage.getItem("sid");

    const response = await axios.post("", {
      query: mutationQuery,
      variables: {
        searchText: searchText,
      },
    });

    return response?.data?.data?.searchClientMattersImpl?.clientMatters || [];
  },
};

export default ltbRequestMutation;
