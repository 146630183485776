import { gql } from "@apollo/client";

export const LOAD_EXPENSE_CODE = gql`
  query {
    getAllExpenseCodes {
      id
      code
      description
    }
    getAllGeoLocations {
      id
      code
      description
    }
    getAllActivityType {
      id
      code
    }
  }
`;

export const LOAD_RATES = gql`
  query getRateByExpenseCode($id: Int!) {
    getRateByExpenseCode(input: { 
      expenseCodeId: $id,
      customerId: 56,
      entryDate: "2023-08-11"
    })
  }
`;

export const LOAD_ALL_ACTIVITY_EXPENSE = gql`
  query {
    getAllActivityExpenses {
      id
      activityStatus{
        id
        code
      }
      activityExpense{
        activityId
        expenseCode
        {
          id
          code
          description
        }
        invoiceID
        description
        note            
        budgetJobPhaseID
  
      }
      activity{
        activityDate
        statusId
        previousId
        nextId
        adjustedDate
        isAdjusted
        billable
        hours
        quantity
        rate
        amount
        tax1Amount
        tax2Amount
        tax3Amount
        tax4Amount
        tax5Amount
        billId
        hoursBilled
        quantityBilled
        rateBilled
        amountBilledClient
        amountBilled
        tax1BilledClient
        tax2BilledClient
        tax3BilledClient
        tax4BilledClient
        tax5BilledClient
        tax1Billed
        tax2Billed
        tax3Billed
        tax4Billed
        tax5Billed
        discountBilled
        billInstruction
        showOnBill
        initialDraftBill
        rateScheduleId
        lockAmountBilled
        billSequence
        unpostCount
        billSplitCustomerId
        billSplitOriginalId
        doNotCharge
        calendarEventId
        geoLocationId
        writeOff
        adjustedBillDate
        reference
        writeDownCodeId
        splitParentId
        multiPayorId
        rangeIndexId
  
      }
      clientEntity
      {
        id
        createdById
        createdDate
        modifiedById
        modifiedDate
        active
        code
        description
        typeId
        remarks
        isVisible
        invoicePaymentTypeId
        lastActivityDate
        externalId
        parentEntityId
  
      }
      customerEntity
      {
        id
        createdById
        createdDate
        modifiedById
        modifiedDate
        active
        code
        description
        typeId
        remarks
        isVisible
        invoicePaymentTypeId
        lastActivityDate
        externalId
        parentEntityId
      }
    }
  }  
`;
