import { gql } from "@apollo/client";

export const GET_ACTIVE_DASHBOARD = gql`
query{
  getActiveDashboard{
      dashboardId
      title
      description
      type
      model
      createdDate
      modifiedDate
      employeeId
  }
}
`;
