import axios from "axios";
import config from "../../../../config"

const axiosInstance = axios.create({
  baseURL: config.graphql_URL_TEMP,
  headers: {
    "Content-Type": "application/json",
    sid: localStorage.getItem("sid"),
  },
});

const dashboardMutation = {

  updateDashboard: async (dto) => {
    const mutation =  `
    mutation{
      updateActiveDashboard(dto: {
          title: "New Title"
          description: "New Description"
          model:${JSON.stringify(dto.model)}
      }){
        dashboardId
        createdDate
        description
        title
        model
      }
    }
  `;

    const response = await axiosInstance.post("", {  query: mutation});

    return response.data;
  },
};


export default dashboardMutation;
