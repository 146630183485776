import React, { useState, useMemo, useEffect } from 'react';
import { IconButton, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import moreIcon from '../../../assets/images/moreIcon.svg';
import FeeCalendarUtils from './utils';
import { SkeletonCard } from './Skeleton';
import { GET_TIME_ENTRIES_BY_CM } from '../graphql/mutations/feeCalendar';
import { useMutation } from '@apollo/client';
import jsUtils from '../../../utils/jsUtils';

function ListedEntryComponent({ item, handleTimeEntry }) {
  if (!item?.Client) {
    item.Client = item.clientCode + ' ' + item.clientDescription;
    item.Matter = item.matterCode + ' ' + item.matterDescription;
  }
  return (
    <div
      className="timeEntry"
      style={{ border: '2px solid #85CD37', cursor: 'pointer' }}
      onClick={() => handleTimeEntry(item.activityId)}
    >
      <div className="client">
        {item?.Client || item?.clientDescription}
      </div>
      <div className="matter">{item?.Matter}</div>
      <div className="details">
        <div>{(item?.hours).toFixed(2)} Hours</div>
        <div>{FeeCalendarUtils.currencyFormatter(item?.amount)}</div>
        <div>{item?.activityDate}</div>
        <div className="status">
          {!item?.status
            ? jsUtils.returnStatusCode(item?.statusId)
            : item?.status}
        </div>
      </div>
      <div className="narrative">{item?.narrative}</div>
    </div>
  );
}

function GroupedEntryComponent({ item, index, handleListing }) {
  const handleEntries = () => {
    handleListing(index, 'list');
  };

  return (
    <div
      className="timeEntry"
      style={{ border: '2px solid #8CD2FA' }}
    >
      <div className="client">{item?.Client}</div>
      <div className="matter">{item?.Matter}</div>
      <div className="details">
        <div>{(item?.totalHours).toFixed(2)} Hours</div>
        <div>
          {FeeCalendarUtils.currencyFormatter(item?.totalAmount)}
        </div>
      </div>
      <div className="moreIcon" onClick={handleEntries}>
        <img src={moreIcon} alt="more" />
      </div>
    </div>
  );
}

export default function Entries({
  data = [],
  viewType,
  setViewType,
  calView,
  handleTimeEntry,
  isLoading,
  yearlyDateRange,
}) {
  const [listOfEntries, setListOfEntries] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [
    getTimeEntriesByCM,
    { data: timeEntries },
  ] = useMutation(GET_TIME_ENTRIES_BY_CM);

  useEffect(() => {
    //Fetching selected Day Data...
    if (timeEntries) {
      setListOfEntries(timeEntries.search.content);
      setLoadingList(false);
    }
  }, [timeEntries]);

  const handleListing = (index, entryView) => {
    setViewType(entryView);
    setLoadingList(true);
    const selectedItem = data[index];
    const { isMonthOrday } = calView;

    if (isMonthOrday === 'month') {
      getTimeEntriesByCM({
        variables: {
          timeEntrySearchRequest: {
            clientId: selectedItem.clientId,
            matterId: selectedItem.matterId,
            date: yearlyDateRange.fromDate,
            toDate: yearlyDateRange.toDate,
            page: 0,
            size: 999999,
            timekeeperId: yearlyDateRange.employeeId,
          },
        },
      });
    } else {
      setListOfEntries(data[index]?.groupedEntries);
      setLoadingList(false);
    }
  };

  const displayedEntries = useMemo(() => {
    if (isLoading || loadingList) {
      return <SkeletonCard minCount={2} maxCount={8} />;
    } else {
      if (viewType === 'grouping') {
        return data?.map((item, i) => (
          <GroupedEntryComponent
            key={i}
            item={item}
            index={i}
            handleListing={handleListing}
          />
        ));
      } else {
        return listOfEntries?.map((item, i) => (
          <ListedEntryComponent
            key={i}
            item={item}
            handleTimeEntry={handleTimeEntry}
          />
        ));
      }
    }
  }, [data, viewType, isLoading, listOfEntries, loadingList]);

  return (
    <div>
      <div className="entries-header">
        {viewType === 'list' && (
          <IconButton
            size="small"
            onClick={() => setViewType('grouping')}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        <Typography>
          {FeeCalendarUtils.returnEntriesHeading(calView, viewType)}
        </Typography>
      </div>
      <div className="entries-content">{displayedEntries}</div>
    </div>
  );
}
