var ColumnVisibility = {
    clientName: true,
    matterName: true,
    timekeeper: true,
    date: true,
    hours: true,
    description: true,
    status: true,
    activity: true,
    createdDate: false,
    modifiedDate: false,
    postedBy: false,
    taskCode: true,
    billable: false,
    location: true,
    service: false,
    activityDate: false,
    note: false,
    startTime: false,
    type: true,
    createdBy: false,
    entryId: false,
    modifiedBy: false,
    phase: false,
    rate: true,
    quantity: true,
    transactionDate : false,
    taxExempt: true,
}

export default ColumnVisibility;