import { literals } from '../enums/literalCodes';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const startTimeString = '00:06:00';
const endTimeString = '23:54:00';

const errorList = ['DisableFinalize', 'DisablePost', 'DisableSave'];
const MAX_SECONDS = 86040;

const jsUtils = {
  getUserTimezone: () => {
    const userTimeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimeZone;
  },

  parseHash: (hashUrl) => {
    return hashUrl
      ?.replace('#', '')
      .split('&')
      .reduce((prev, item) => {
        return Object.assign(
          {
            [item.split('=')[0]]: decodeURIComponent(
              item.split('=')[1]
            ),
          },
          prev
        );
      }, {});
  },

  formatTime: (time) => {
    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, '0');
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  },

  hoursToSeconds: (hours) => {
    const seconds = hours * 60 * 60;
    return seconds;
  },
  secondIntoHours: (seconds) => {
    return (seconds / 3600).toFixed(2);
  },

  GetAmountByDuration: (time) => {
    if (parseFloat(time) === 0 || time === '' || time === '0') {
      return 0;
    }
    const amount = parseFloat(time) * 50; // 1 hour = 3600 seconds
    return amount.toFixed(1);
  },
  GetTotalAmount: (row) => {
    return parseFloat(row.hours * row.rate).toFixed(2);
  },

  GetTotalAmountForTaskFee: (row) => {
    return parseFloat(row.quantity * row.rate).toFixed(2);
  },

  RequiredFieldsValidation: (obj) => {
    for (let key in obj) {
      if (
        obj[key] === null || // check for null
        (typeof obj[key] === 'number' && obj[key] === 0) || // check for zero (integer)
        (typeof obj[key] === 'string' && obj[key].trim() === '') || // check for empty string
        typeof obj[key] === 'undefined' ||
        obj[key] === false ||
        obj[key] === '0.0'
      ) {
        return false; // return false if any condition is not fulfilled
      }
    }
    return true; // return true if all conditions are fulfilled
  },

  UnsavedRequiredFieldsValidation: (obj) => {
    // Check how many fields of object are not null or empty

    let numberOfFieldsAdded = 0;

    for (let key in obj) {
      if (
        obj[key] != '' ||
        (typeof obj[key] === 'number' && obj[key] !== 0)
      ) {
        numberOfFieldsAdded++;
      }
    }

    if (numberOfFieldsAdded > 1) {
      // activityDate is added by default so we will ignore it
      return true;
    } else {
      return false;
    }
  },
  mapObjectToModel: (model, object) => {
    const mappedObject = { ...model };

    Object.keys(mappedObject).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        mappedObject[key] = object[key];
      }
    });

    return mappedObject;
  },

  // Convert a time string in the format "hh:mm:ss" into seconds since midnight
  timeToSeconds: (timeString) => {
    const timeParts = timeString.split(':');
    if (timeParts.length !== 3) {
      throw new Error(`Invalid time format: ${timeString}`);
    }
    const [hours, minutes, seconds] = timeParts.map(Number);
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      throw new Error(`Invalid time format: ${timeString}`);
    }
    return hours * 3600 + minutes * 60 + seconds;
  },
  isTimeBetween: (time) => {
    const timeParts = time.split(':').map(Number);
    const startTimeParts = startTimeString.split(':').map(Number);
    const endTimeParts = endTimeString.split(':').map(Number);

    // Convert times to total minutes
    const timeInMinutes = timeParts[0] * 60 + timeParts[1];
    const startTimeInMinutes =
      startTimeParts[0] * 60 + startTimeParts[1];
    const endTimeInMinutes = endTimeParts[0] * 60 + endTimeParts[1];

    // Compare times
    return (
      timeInMinutes >= startTimeInMinutes &&
      timeInMinutes <= endTimeInMinutes
    );
  },
  validateHourInput: (value) => {
    value = value === '.' ? '0.' : value;
    const hoursToSeconds = jsUtils.hoursToSeconds(value);
    //First checking if the input values are not greater or exceeding the Max time limit(86040 seconds) or (23.90 hours)
    if (MAX_SECONDS >= hoursToSeconds) {
      //Validating the input field to max two decimal places.
      if (jsUtils.hourValidationRegex(value)) {
        return true;
      }
      return false;
    }
  },
  hourValidationRegex: (value) => {
    //Only a single leading zero before a decimal point
    //An optional decimal point followed by any number of digits
    //No negative values
    //No string or special characters allowed
    //MAX_SECONDS limit = 86040; (23.90)
    //MIN_SECONDS limit = 360;  (0.10)
    // {0,1} => 0 = min digit, 1 = max digit

    const regex =
      /^\.[0-9]{0,2}$|^((2[0-4]|1[0-9]|[1-9])|0)(\.[1-9][0-9]{0,1}|\.[0-9]{0,2})?$/;

    return (
      regex.test(value) || value === '' || value.charCodeAt(0) === 8
    );
  },
  rateValidationRegex: (value) => {
    const hourRegex = /^\d+(\.\d{0,2})?$/;
    return (
      hourRegex.test(value) ||
      value === '' ||
      value.charCodeAt(0) === 8
    );
  },

  CreateTimerList: (Data) => {
    const existingData = JSON.parse(
      localStorage.getItem('TimerList')
    );
    const TimerList = existingData === null ? [] : existingData;
    // If the data exists, parse it from string to array
    const index = TimerList.findIndex(
      (time) =>
        time.ClientId === Data.ClientId &&
        time.MatterId === Data.MatterId &&
        time.timeKeeperId === Data.timeKeeperId
    );
    if (index !== -1) {
      TimerList[index].time = Data.time;
      TimerList[index].timerId = Data.timerId;
      localStorage.setItem('TimerList', JSON.stringify(TimerList));
    } else {
      TimerList.push(Data);
      localStorage.setItem('TimerList', JSON.stringify(TimerList));
    }
  },
  GetTimerDetailsByIDs: (Data) => {
    const existingData = JSON.parse(
      localStorage.getItem('TimerList')
    );

    if (existingData) {
      let TimerDetails = existingData.find(
        (timer) =>
          timer.ClientId === Data.ClientId &&
          timer.MatterId === Data.MatterId &&
          timer.timeKeeperId === Data.timeKeeperId
      );
      if (typeof TimerDetails === 'undefined') {
        return false;
      } else {
        return TimerDetails;
      }
    }
    return false;
  },

  UpdateTimerCounterByTimerID: (Id) => {
    const existingData = JSON.parse(
      localStorage.getItem('TimerList')
    );

    if (existingData) {
      let index = existingData.findIndex(
        (timer) => timer.timerId === Id
      );
      if (index !== -1) {
        existingData[index].time = existingData[index].time + 1;
        localStorage.setItem(
          'TimerList',
          JSON.stringify(existingData)
        );
      }
    }
    return false;
  },
  UpdateTimerStatusByTimerID: (Id, action) => {
    const existingData = JSON.parse(
      localStorage.getItem('TimerList')
    );

    if (existingData) {
      let index = existingData.findIndex(
        (timer) => timer.timerId === Id
      );
      if (index !== -1) {
        existingData[index].status = action;
        localStorage.setItem(
          'TimerList',
          JSON.stringify(existingData)
        );
      }
    }
    return false;
  },

  GetRunningTimerData: (Data, action) => {
    const existingData = JSON.parse(
      localStorage.getItem('TimerList')
    );

    if (existingData) {
      let index = existingData.find(
        (timer) =>
          timer.ClientId === Data.ClientId &&
          timer.MatterId === Data.MatterId &&
          timer.timeKeeperId === Data.timeKeeperId &&
          timer.status === 'STARTED'
      );
      if (index !== -1) {
        existingData[index].status = action;
        localStorage.setItem(
          'TimerList',
          JSON.stringify(existingData)
        );
      }
    }
    return false;
  },
  RemoveTimer: (Data) => {
    const existingData = JSON.parse(
      localStorage.getItem('TimerList')
    );

    if (existingData) {
      let indexToRemove = existingData.findIndex(
        (timer) =>
          timer.ClientId === Data.ClientId &&
          timer.MatterId === Data.MatterId &&
          timer.timeKeeperId === Data.timeKeeperId
      );
      if (indexToRemove !== -1) {
        existingData.splice(indexToRemove, 1);
        localStorage.setItem(
          'TimerList',
          JSON.stringify(existingData)
        );
      }
    }
    return false;
  },
  isGUID: (input) => {
    const pattern = /^(?=.*[a-zA-Z0-9])(?=.*-)/;
    return pattern.test(input);
  },
  extractRestrictedWords: (
    textField,
    regexPattern,
    isRegexCaseSensitive
  ) => {
    const regex = new RegExp(
      regexPattern,
      isRegexCaseSensitive ? 'gs' : 'igs'
    );
    const matches = textField.match(regex);
    return matches ? Array.from(new Set(matches)) : [];
  },

  sanitizedText: (text) => {
    const sanitized = text?.replace(/<[^>]+>|&nbsp;/g, '');
    const regex = /(\b\w+\b)/gi;
    return sanitized?.match(regex);
  },
  decodeHtmlEntities: (input) => {
    const replacedText = input.replace(/<div><br><\/div>/g, '\\n ');

    const parser = new DOMParser();
    const decodedString = parser.parseFromString(
      `<!doctype html><body>${replacedText}`,
      'text/html'
    ).body.textContent;

    return decodedString;
  },
  formattedText: (words, restrictedWords) => {
    return words.reduce((result, word) => {
      const isRestricted = restrictedWords.includes(word);
      if (isRestricted) {
        const tooltip = `You cannot use ${word}`;
        const span = `<span class="restricted-words" data-tooltip="${tooltip}" data-tooltip-position="bottom">${word}</span>`;
        return result + (result ? ' ' : '') + span;
      } else {
        return result + (result ? ' ' : '') + word;
      }
    }, '');
  },

  updateComplianceErrors: (
    fieldText,
    regexPattern,
    isRegexCaseSensitive
  ) => {
    //Extraction the restricted word's Array from the error message
    const restrictedWords = jsUtils.extractRestrictedWords(
      fieldText,
      regexPattern,
      isRegexCaseSensitive
    );

    //Checking if the narrative contains restricted words
    if (restrictedWords.length === 0) {
      return fieldText;
    }
    //if restricted words contains, do sanitize it by removing the span and extra whitespace
    const sanitizedText = jsUtils.sanitizedText(fieldText);
    //Hightlight the restricted words and return the result
    return jsUtils.formattedText(sanitizedText, restrictedWords);
  },
  updateTimerDto: (timerId, event) => {
    const timerEventsMap = localStorage.getItem('timerEventsMap');

    let timerModel = {
      timerStatus: event,
      occuredOn: new Date().toISOString(),
    };
    let timersMap = {};
    let timerEvents = [];

    if (timerEventsMap) {
      timersMap = JSON.parse(timerEventsMap);
      if (timersMap[timerId]) timerEvents = timersMap[timerId];
    }

    timerEvents.push(timerModel);
    timersMap[timerId] = timerEvents;

    localStorage.setItem('timerEventsMap', JSON.stringify(timersMap));
  },

  resetTimerDto: (timerId) => {
    let timerEventsMap = localStorage.getItem('timerEventsMap');
    if (timerEventsMap) {
      if (timerId) {
        let tmap = JSON.parse(timerEventsMap);
        delete tmap[timerId];
        localStorage.setItem('timerEventsMap', JSON.stringify(tmap));
      } else {
        localStorage.removeItem('timerEventsMap');
      }
    }
  },

  getTimerDto: (timerId) => {
    let timerEventsMap = localStorage.getItem('timerEventsMap');
    if (timerEventsMap) {
      if (timerId) {
        let tmap = JSON.parse(timerEventsMap);
        return tmap[timerId];
      }
    }
    return [];
  },

  timeEntryTimerEventRequest: (id, event) => {
    let date = new Date().toISOString();
    return { activityId: id, timerStatus: event, occuredOn: date };
  },

  returnTimerValue: (timerDto) => {
    if (timerDto?.timerStartDateTime) {
      const userTimeZone = jsUtils.getUserTimezone();
      const startDate = dayjs(timerDto.timerStartDateTime).tz(
        userTimeZone
      );
      const endDate = timerDto.timerStopDateTime
        ? dayjs(timerDto.timerStopDateTime).tz(userTimeZone)
        : dayjs();

      const currentTime = Math.floor((endDate - startDate) / 1000);
      const existingTime = !timerDto.timerStopDateTime
        ? parseInt(timerDto.timeElapsed)
        : 0;
      return currentTime + existingTime; // return seconds
    }
    return 0;
  },
  buildTimeKeeperDesc(timeKeeper, empNameType) {
    return (
      timeKeeper?.lastName +
      ' ' +
      timeKeeper?.firstName +
      ' ' +
      timeKeeper?.middleInitial
    );
  },
  handleTimeEntries: (item) => {
    let time_entry =
      item.activityDate &&
      `${item.activityDate.split('-')[1]}/${
        item.activityDate.split('-')[2]
      }/${item.activityDate.split('-')[0]}`;
    const transaction_date =
      item.transactionDate &&
      `${item.transactionDate.split('-')[1]}/${
        item.transactionDate.split('-')[2]
      }/${item.transactionDate.split('-')[0]}`;

    return {
      id: item?.activityId,
      time: jsUtils.returnTimerValue(item?.timerDto),
      clientName: item?.clientDescription || '18635 Skate Palace',
      clientMattersDescription:
        item?.clientCode +
        ' ' +
        item?.clientDescription +
        ' - ' +
        item?.matterCode +
        ' ' +
        item?.matterDescription,
      matterName: item?.matterDescription || '02 General Matter',
      date: new Date(time_entry),
      hours: item.hours !== null ? item.hours : '',
      amount: item.amount !== null ? item.amount : 0,
      description: item.narrative,
      status: jsUtils.returnStatusCode(item.statusId),
      istracker: false,
      postedById: 1,
      activityTypeId: 15,
      activityId: item.activityId,
      activityCodeId: item.activityCodeId,
      geoLocationCode: item.geoLocationCode,
      geoLocationDescription: item.geoLocationDescription,
      geoLocationId: item.geoLocationId,

      taskCodeId: item.taskCodeId,
      activityDate: new Date(time_entry).toLocaleDateString('es-pa'),
      transactionDate: new Date(transaction_date).toLocaleDateString(
        'es-pa'
      ),
      note: item.note,
      writeOff: false,
      doNotCharge: false,
      isPost: true,
      releasedBy: 1,
      createdDate: new Date(item.createdDate).toLocaleDateString(
        'es-pa'
      ),
      createdBy: `${item?.createdBy?.firstName || ''} ${
        item?.createdBy?.lastName || ''
      }`,
      // service: item.taskCodeId,
      entryId: item.activityId,
      billable: item.billable,
      billableCode: jsUtils.getBillableCode(item.billable),
      modifiedDate: new Date(item.modifiedDate).toLocaleDateString(
        'es-pa'
      ),
      modifiedBy: `${item?.modifiedBy?.firstName || ''} ${
        item?.modifiedBy?.lastName || ''
      }`,
      location:
        (item?.geoLocationCode ? item.geoLocationCode + ' - ' : '') +
        (item?.geoLocationDescription || ''),
      taskCode:
        (item?.taskCode ? item.taskCode + ' - ' : '') +
        (item.taskDescription || ''),
      activity:
        (item?.activityCode ? item.activityCode + ' - ' : '') +
        (item.activityDescription || ''),

      type: item.activityTypeDescription,
      timekeeper: jsUtils.buildTimeKeeperDesc(
        item?.timekeeper,
        item?.empNameType
      ),
      startTime: item?.activityTime,
      phase: item?.budgetJobPhaseId,
      timerDto: item.timerDto,
      employeeId: item.timekeeper?.employeeId,
      customerId: item?.customerId,
      clientId: item?.clientId,
      rate: item?.rate,
      taxExempt: item?.timekeeper?.taxExempt,
      quantity: item?.quantity,
    };
  },
  getBillableCode: (billable) => {
    if (billable === 0) {
      return 'No';
    } else if (billable === 1) {
      return 'Yes';
    } else {
      return 'Admin';
    }
  },
  getBillableInfo: (billableType) => {
    let billableCode, billable;

    switch (billableType.code) {
      case 'Expense Only':
        billableCode = 'No';
        billable = 0;
        break;
      case 'Fee Only':
        billableCode = 'Yes';
        billable = 1;
        break;
      case 'NonBillable':
      case 'Billable':
        billableCode =
          billableType.code === 'NonBillable' ? 'No' : 'Yes';
        billable = parseInt(billableType.id);
        break;
      default:
        billableCode = billableType.code;
        billable = parseInt(billableType.id);
    }

    return { billable, billableCode };
  },
  getStateForEdit: (timeEntries) => {
    return {
      time: jsUtils.returnTimerValue(timeEntries?.timerDto),
      hours: timeEntries.hours !== null ? timeEntries.hours : '',
      amount: timeEntries.amount
        ? parseFloat(timeEntries.amount).toFixed(2)
        : '',
      description: timeEntries.narrative,
      activityId: timeEntries.activityId,
      activityDate: dayjs(timeEntries.activityDate),
      date: dayjs(timeEntries.activityDate),
      note: timeEntries.note,
      status: jsUtils.returnStatusCode(timeEntries.statusId),
      writeOff: false,
      doNotCharge: false,
      billable: timeEntries.billable,
      billableCode: jsUtils.getBillableCode(timeEntries.billable),
      modifiedById: 1,
      phase: timeEntries?.budgetJobPhaseId,
      timerDto: timeEntries.timerDto,
      employeeId: timeEntries.timekeeper?.employeeId,
      geoLocationId: timeEntries?.geoLocationId,
      customerId: timeEntries?.customerId,
      clientId: timeEntries?.clientId,
      activityType: timeEntries?.activityTypeCode,
      activityCodeId: timeEntries?.activityCodeId,
      activityDescription: timeEntries?.activityCodeId
        ? timeEntries?.activityDescription
        : null,
      activityCode: timeEntries?.activityCodeId
        ? timeEntries?.activityCode
        : null,
      taskCode: timeEntries?.taskCode ? timeEntries?.taskCode : null,
      taskDescription: timeEntries?.taskDescription
        ? timeEntries?.taskDescription
        : null,
      geoLocationDescription: timeEntries?.geoLocationId
        ? timeEntries.geoLocationDescription
        : null,
      geoLocationCode: timeEntries?.geoLocationId
        ? timeEntries.geoLocationCode
        : null,
      clientMattersDescription:
        timeEntries?.clientCode +
        ' ' +
        timeEntries?.clientDescription +
        ' - ' +
        timeEntries?.matterCode +
        ' ' +
        timeEntries?.matterDescription,
      timekeeperDescription: jsUtils.buildTimeKeeperDesc(
        timeEntries?.timekeeper,
        timeEntries?.empNameType
      ),
      taskCodeId: timeEntries?.taskCodeId,
      transactionDate: dayjs(timeEntries?.transactionDate).format(
        'MM/DD/YYYY'
      ),
      periodDate: timeEntries?.activityDate,
      quantity: timeEntries?.quantity,
      rate: timeEntries?.rate || 0,
      taxExempt: timeEntries.timekeeper.taxExempt ? 'Yes' : 'No',
    };
  },
  returnStatusCode: (statusId) => {
    switch (statusId) {
      case -1:
        return literals.LOCKED;
      case -2:
        return literals.FINALIZED;
      case 1:
        return literals.LOCKED;
      case 2:
        return literals.FINALIZED;
      case 3:
        return literals.POSTED;
      case 4:
        return literals.TAGGED;
      case 5:
        return literals.BILLED;
      case 6:
        return literals.PAID;
      case 7:
        return literals.ADJUSTED;
      case 8:
        return literals.REVERSED;
      case 9:
        return literals.CLOSED;
      case 10:
        return literals.VOID;
      case 9998:
        return literals.NOCHARGE;
      case 9999:
        return literals.WRITEOFF;
      default:
        return literals.DRAFT;
    }
  },

  encodeHtmlEntities: (input) => {
    return input?.replace(/\\n|\n/g, '<div><br></div>');
  },

  getAutoCorrectedText: (input, dictionary) => {
    if (typeof dictionary === 'undefined') {
      return input;
    }

    const replacedString = input.replace(/\\n|\n/g, '\n');
    const words = replacedString.split(/(\s+)/);

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      let correctedWord = word;
      let removedPunctuation = null;

      const hasPunctuation = /[.,]$/.test(word);
      const code = hasPunctuation ? word.slice(0, -1) : word;
      removedPunctuation = hasPunctuation && word.slice(-1);

      const matchingEntries = dictionary.filter((x) =>
        x.isCaseSensitive
          ? x.code === code
          : x.code.toLowerCase() === code.toLowerCase()
      );

      if (matchingEntries.length > 0) {
        const isPublicEntry = matchingEntries.find(
          (x) => x.isPublic === false
        );
        if (typeof isPublicEntry !== 'undefined') {
          correctedWord = isPublicEntry.description;
        } else {
          correctedWord = matchingEntries[0].description;
        }
        if (hasPunctuation) {
          correctedWord += removedPunctuation;
        }
      }
      words[i] = correctedWord;
    }

    return words.join('');
  },
  getSubmitButtonDisableStatus: (buttonType, status, isTracking) => {
    if (status === literals.DRAFT && buttonType === 'save') {
      return false;
    }

    if (
      (isTracking ||
        status === literals.POSTED ||
        status === literals.TAGGED) &&
      buttonType === 'post'
    ) {
      return true;
    }

    return false;
  },
  getFieldName: (field) => {
    switch (field) {
      case 'EntryDate':
        return 'date';
      case 'TaskCode':
        return 'taskCodeId';
      case 'ActivityCode':
        return 'activityCodeId';
      case 'Hours':
        return 'hours';
      case 'Amount':
        return 'amount';
      case 'Narrative':
        return 'description';
      case 'Note':
        return 'note';
      case 'Employee':
        return 'employeeId';
      case 'CustomerClient':
        return 'customerId';
      case 'GeoLocation':
        return 'geoLocationId';
      case 'Billable':
        return 'billable';
      default:
        return 'no field';
    }
  },
  getExpenseFieldName: (field) => {
    switch (field) {
      case 'EntryDate':
        return 'date';
      case 'ExpenseCode':
        return 'expenseCodeId';
      case 'Quantity':
        return 'quantity';
      case 'Hours':
        return 'hours';
      case 'Amount':
        return 'amount';
      case 'Narrative':
        return 'description';
      case 'Note':
        return 'note';
      case 'Employee':
        return 'employeeId';
      case 'CustomerClient':
        return 'customerId';
      case 'GeoLocation':
        return 'geoLocationId';
      case 'Billable':
        return 'billable';
      case 'Rate':
        return 'rate';
      default:
        return 'no field';
    }
  },

  getFieldSeverity: (error) => {
    if (!error) return '';

    if (errorList.includes(error.errorLevel)) {
      return 'error';
    } else {
      return 'warning';
    }
  },
  checkIfObjectsEqual: (obj1, obj2) => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== 'object' ||
      obj1 === null ||
      typeof obj2 !== 'object' ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (
        !keys2.includes(key) ||
        !jsUtils.checkIfObjectsEqual(obj1[key], obj2[key])
      ) {
        return false;
      }
    }

    return true;
  },
  getMonthRange: (month, year) => {
    const fromDate = dayjs(`${year}-${month}-01`).format(
      'YYYY-MM-DD'
    );
    const toDate = dayjs(`${year}-${month}-01`)
      .endOf('month')
      .format('YYYY-MM-DD');
    return { fromDate, toDate };
  },
  getYearRange: (month, year) => {
    const fromDate = dayjs(`${year}-01-01`).format('YYYY-MM-DD');
    const toDate = dayjs(`${year}-${month}-01`)
      .endOf('year')
      .format('YYYY-MM-DD');
    return { fromDate, toDate };
  },
  getQueryParam: (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  },
  debFlag: null,
  debounce: (doSomething, time, ...params) => {
    if (jsUtils.debFlag) {
      window.clearTimeout(jsUtils.debFlag);
      jsUtils.debFlag = null;
    }
    if (!jsUtils.debFlag) {
      jsUtils.debFlag = window.setTimeout(() => {
        jsUtils.debFlag = null;
        doSomething && doSomething(...params);
      }, time);
    }
  },
  columnSortingMethod: (dataV1, dataV2) => {
    let pattern = /^[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
    if (
      pattern.test(dataV1?.charAt(0)) &&
      !pattern.test(dataV2?.charAt(0))
    ) {
      return 1;
    }
    if (
      !pattern.test(dataV1?.charAt(0)) &&
      pattern.test(dataV2?.charAt(0))
    ) {
      return -1;
    }
    if (
      pattern.test(dataV1?.charAt(0)) &&
      pattern.test(dataV2?.charAt(0))
    ) {
      return dataV1.localeCompare(dataV2);
    }
    return parseInt(dataV1) - parseInt(dataV2);
  },
  deepClone: (obj) => {
    return JSON.parse(JSON.stringify(obj));
  },
};
export default jsUtils;
