import axios from "axios";
import config from "../../../../config";

const axiosInstance = axios.create({
  baseURL: `${config.baseUrl}${"/expense-management/graphql"}`,
  headers: {
    "Content-Type": "application/json",
    sid: localStorage.getItem("sid"),
  },
});

const expenseRequestMutation = {
  saveExpenseEntry: async (input) => {
    const mutation = `
    mutation ($ActivityExpenseSaveDraftInput: ActivityExpenseSaveDraftInput!) {
      saveActivityExpenseDraft(input: $ActivityExpenseSaveDraftInput) {
        complianceMessages{
          field
          errorLevel
          message
        }
        hasWarning
        disableSave
        disablePost
      }
    }
    `;

    const response = await axiosInstance.post("", {
      query: mutation,
      variables: { ActivityExpenseSaveDraftInput: input },
    });

    return response.data;
  },
  postExpenseEntry: async (input) => {
    const mutation = `
    mutation($ActivityExpenseSaveDraftInput: ActivityExpenseSaveDraftInput!) {
      postExpense(input:$ActivityExpenseSaveDraftInput) 
      {
        complianceMessages{
          field
          errorLevel
          message
        }
        expenseEntryDto{
          activityStatus{
            id
            code
          }
          activityExpense{
            activityId
            expenseCode
              {
                id
                code
                description
              }
            invoiceID
            description
            note            
            budgetJobPhaseID
          }
          activity{
            activityDate
            statusId
            previousId
            nextId
            adjustedDate
            isAdjusted
            billable
            hours
            quantity
            rate
            amount
            tax1Amount
            tax2Amount
            tax3Amount
            tax4Amount
            tax5Amount
            billId
            hoursBilled
            quantityBilled
            rateBilled
            amountBilledClient
            amountBilled
            tax1BilledClient
            tax2BilledClient
            tax3BilledClient
            tax4BilledClient
            tax5BilledClient
            tax1Billed
            tax2Billed
            tax3Billed
            tax4Billed
            tax5Billed
            discountBilled
            billInstruction
            showOnBill
            initialDraftBill
            rateScheduleId
            lockAmountBilled
            billSequence
            unpostCount
            billSplitCustomerId
            billSplitOriginalId
            doNotCharge
            calendarEventId
            geoLocationId
            writeOff
            adjustedBillDate
            reference
            writeDownCodeId
            splitParentId
            multiPayorId
            rangeIndexId
          }
          clientEntity
          {
            id
            createdById
            createdDate
            modifiedById
            modifiedDate
            active
            code
            description
            typeId
            remarks
            isVisible
            invoicePaymentTypeId
            lastActivityDate
            externalId
            parentEntityId
          }
          customerEntity
          {  
            id
            createdById
            createdDate
            modifiedById
            modifiedDate
            active
            code
            description
            typeId
            remarks
            isVisible
            invoicePaymentTypeId
            lastActivityDate
            externalId
            parentEntityId
          }
        }
        hasWarning
        disableSave
        disablePost
      }
    }
    `;

    const response = await axiosInstance.post("", {
      query: mutation,
      variables: { ActivityExpenseSaveDraftInput: input },
    });

    return response.data;
  },

  deleteExpenseEntry: async (entryId) => {
    const mutation = `
    mutation deleteActivityExpense {
      deleteActivityExpense(input: {
        id: ${entryId}
        ts: null,
        modifiedById: 2,
        modifiedDate: null,
        invoiceId: null
      })
    }
  `;

    const response = await axiosInstance.post('', {
      query: mutation,
      variables: { deleteActivityExpense: entryId },
    });

    return response.data;
  },
  searchClientMatters: async (searchText) => {
    const mutationQuery = `
      query CreateClientMatter($searchText: String!) {
        searchClientMattersImpl(searchText: $searchText, moduleType: "EXPENSE") {
          clientMatters {
            clientId
            clientCode
            clientDesc
            matterId
            matterCode
            matterDesc
            description
            billableType {
              id
              code
            }
            enforceBillableFlag
          }
        }
      }
    `;

    //Creating Temporary instance because it has different Base URL.
    axios.defaults.baseURL = config.graphql_URL_TEMP;
    axios.defaults.headers.sid = localStorage.getItem("sid");

    const response = await axios.post("", {
      query: mutationQuery,
      variables: {
        searchText: searchText,
      },
    });

    return response?.data?.data?.searchClientMattersImpl?.clientMatters || [];
  },
  getTimeKeepers: async (searchText, customerId) => {
    const mutationQuery = `
    query GetMatchedTimeKeepers($searchText: String!, $matterId: Int){
      getTimeKeepers(searchText: $searchText, matterId: $matterId){
          timeKeepers{
            id
            code
            description
            employeeEntity {
              taxExempt
            }
          }
      }
  }`;

    // //Creating Temporary instance because it has different Base URL.
    axios.defaults.baseURL = config.graphql_URL_TEMP;
    axios.defaults.headers.sid = localStorage.getItem("sid");

    const response = await axios.post("", {
      query: mutationQuery,
      variables: {
        searchText: searchText,
        matterId: parseInt(customerId)
      },
    });
    let timekeepers = response?.data?.data?.getTimeKeepers?.timeKeepers || [];

    return timekeepers;
  },
};

export default expenseRequestMutation;
