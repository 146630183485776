import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import "./commonDialog.scss"

const CommonDialog = ({ open = false, title, children, onClose}) => {
return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        PaperProps={{
          style: {
            border: '3px solid rgba(0, 80, 195, 0.12)',
            borderRadius: '10px',
            boxSizing: 'border-box', 
            width: '32rem'
          },
       }}
      >
        <DialogTitle >
          {title}
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CommonDialog;