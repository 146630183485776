import React,{memo} from 'react';
import { Snackbar } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { closeSnackbar } from '../../redux-toolkit/slices/snackbar';
import StyledCustomAlert from "./StyledAlert";

const ToastMessage = () => {

  const dispatch = useDispatch();
  const { open, message, severity, width } = useSelector((state) => state.snackbar);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <StyledCustomAlert
        severity={severity}
        message={message}
        onClose={handleClose}
        width={width}
      />
    </Snackbar>
  )
}

export default memo(ToastMessage);
