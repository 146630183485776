import React from "react"
import Card from '@mui/material/Card';
import {Grid} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { literals } from "../../enums/literalCodes";
import "./index.scss";

import expenseWidgetMetadata from "../Expense/AddExpenseEntry/ExpenseWidgetMetadata";
import timeEntryWidgetMetadata from "../TimeEntry/TimeEntryWidgetMetadata";

export default function NewWidgetSelector({handleAddNewWidget, currentTabTitle}) {
    const getAvailableWidgets = () => {
        switch(currentTabTitle) {
            case literals.TIME_ENTRY:
                return timeEntryWidgetMetadata;
            case literals.EXPENSE:
                return expenseWidgetMetadata;
            default:
                return [];
        }
    }
    
    return (
        <div className="widget-card-container">
            <Typography className="dashboard-title">{literals.SELECT_A_WIDGET_TO_ADD_TO_YOUR_DASHBOARD}</Typography>
            <Grid container spacing={1}>
                {getAvailableWidgets().map((widget, i) => {
                    return (
                        <Grid item sm={12} md={6} lg={4} key={i}>
                            <Card className="time-entry-card" onClick={() => handleAddNewWidget(widget.type)}>
                                <CardMedia
                                    sx={{ height: 170, background: "#2DA0EB" }}
                                    component="img"
                                    image={widget.image}
                                    />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {widget.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {widget.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}
