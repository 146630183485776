import ltbRequestMutation from "./Mutations";

const ltbService = {
  searchClientMatters: async (searchText) => {
    return await ltbRequestMutation.searchClientMatters(searchText);
  },
  getLtbDeadlineUrl: async (input) => {
    return await ltbRequestMutation.getLtbDeadlineUrl(input);
  },
};

export default ltbService;
