import dashboardMutation from "./Mutations";

const dashboardService = {
  updateDashboard: async (dashboardId, dto) => {
    return await dashboardMutation.updateDashboard(dashboardId, dto);
  },
  
};

export default dashboardService;
