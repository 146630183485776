import { createSlice } from "@reduxjs/toolkit";
import {literals} from "../../enums/literalCodes";

const initialState = {
  selectedTab : literals.TIME_ENTRY_DASHBOARD_TAB, 
  TimeEntryCards: true,
  TodaysWorkCards: true,
  ClientReportCards: true,
  AddNewTabCards: true,
  checkStatesForChanges: false,
  wantedToMove: "",
  TimeEntry : false,
  TimeEntryGrid : false,
  RecentClientMatter : false,
  RecentClientMatterState : null,
  RecentClientMatterTimeEntry : false,
  TimeEntryData: {}
};

const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    ToggleTimeEntryTabs: (state, action) => {
      state.selectedTab = action.payload;
    },
    ToggleTimeEntry: (state, action) => {
      state.TimeEntry = action.payload;
    },
    ToggleTimeEntryGrid: (state, action) => {
      state.TimeEntryGrid = action.payload;
    },
    ToggleRecentClientMatter: (state, action) => {
      state.RecentClientMatter = action.payload;
    },
    ToggleRecentClientMatterTimeEntry: (state, action) => {
      state.RecentClientMatterTimeEntry = action.payload;
    },
    CreateRecentClientMatterState: (state, action) => {
      state.RecentClientMatterState = action.payload;
    },
    ToggleCheckStateForChanges: (state, action) => {
      state.checkStatesForChanges = action.payload;
    },
    ToggleWantedToMove: (state, action) => {
      state.wantedToMove = action.payload;
    },
    ToggleTimeEntryData: (state, action) => {
      state.TimeEntryData = action.payload
    }
  },
});

export const {ResetAddWidget,ToggleTimeEntryTabs,ToggleTimeEntry,ToggleTimeEntryGrid,ToggleRecentClientMatter, ToggleRecentClientMatterTimeEntry,CreateRecentClientMatterState,ToggleCheckStateForChanges, ToggleWantedToMove, ToggleTimeEntryData } = tabSlice.actions;

export default tabSlice.reducer;