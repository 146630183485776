import { axiosInstance } from "../axiosInstance";
import config from "../../config";
import axios from "axios";

const timeEntryMutation = {
  postTimeEntry: async (timeEntryDto) => {
    const mutation = `
    mutation postTimeEntry($TimeEntryRequest: TimeEntryRequest) {
      postTimeEntry(timeEntryRequest: $TimeEntryRequest) {
          complianceMessages {
              field
              errorLevel
              message
              regex
              canHighlightErrors
              isRegexCaseSensitive
          }
          procedureMessage {
              errorCode,
              sqlState
              message
          }
          timeEntryDto {
              activityId
              narrative
              note
              activityTime
              activityCode
              activityDescription
              taskCode
              taskDescription
              budgetJobPhaseId
              statusId
              billable
              createdDate
              modifiedDate
              activityDate
              transactionDate
              hours
              amount
              activityTypeCode
              activityTypeDescription
              geoLocationCode
              geoLocationDescription
              matterCode
              matterDescription
              clientCode
              clientDescription
              createdBy {
                  employeeId
                  firstName
                  middleInitial
                  lastName
                  employeeType {
                      id
                      code
                      description
                  }
              }
              modifiedBy {
                  employeeId
                  firstName
                  middleInitial
                  lastName
                  employeeType {
                      id
                      code
                      description
                  }
              }
              timekeeper {
                  employeeId
                  firstName
                  middleInitial
                  lastName
                  employeeType {
                      id
                      code
                      description
                  }
              }
              timerDto {
                timerId
                activityId
                createdById
                createdDate
                modifiedById
                modifiedDate
                timerStartDateTime
                timerStopDateTime
                timeElapsed
            }
          }
      }
  }
        `;

    const response = await axiosInstance.post("", {
      query: mutation,
      variables: { TimeEntryRequest: timeEntryDto },
    });

    // return the data from the response
    return response.data;
  },

  addTimeEntry: async (timeEntryDto) => {
    const mutation = `
    mutation addTimeEntry($TimeEntryRequest: TimeEntryRequest) {
      addUpdateTimeEntryDraft(timeEntryRequest: $TimeEntryRequest) {
          complianceMessages {
              field
              errorLevel
              message
              regex
              canHighlightErrors
              isRegexCaseSensitive
          }
          procedureMessage {
              errorCode,
              sqlState
              message
          }
          timeEntryDto {
              activityId
              narrative
              note
              activityTime
              activityCode
              activityDescription
              taskCode
              taskDescription
              budgetJobPhaseId
              statusId
              billable
              createdDate
              modifiedDate
              activityDate
              transactionDate
              rate
              quantity
              hours
              amount
              activityTypeCode
              activityTypeDescription
              geoLocationCode
              geoLocationDescription
              matterCode
              matterDescription
              clientCode
              clientDescription
              createdBy {
                  employeeId
                  firstName
                  middleInitial
                  lastName
                  employeeType {
                      id
                      code
                      description
                  }
              }
              modifiedBy {
                  employeeId
                  firstName
                  middleInitial
                  lastName
                  employeeType {
                      id
                      code
                      description
                  }
              }
              timekeeper {
                  employeeId
                  firstName
                  middleInitial
                  lastName
                  employeeType {
                      id
                      code
                      description
                  }
              }
              timerDto {
                timerId
                activityId
                createdById
                createdDate
                modifiedById
                modifiedDate
                timerStartDateTime
                timerStopDateTime
                timeElapsed
            }
          }
      }
  }
    `;

    const response = await axiosInstance.post("", {
      query: mutation,
      variables: { TimeEntryRequest: timeEntryDto },
    });

    // return the data from the response
    return response.data;
  },

  savePostedTimeEntry: async (timeEntryDto) => {

    let { activityId, description, note } = timeEntryDto;
    let timeEntryId = activityId;
    let narrative = description;

    const mutation = `
      mutation savePostedTimeEntry($timeEntryId: Int!, $narrative: String!, $note: String!) {
        savePostedTimeEntry(timeEntryId: $timeEntryId, narrative: $narrative, note: $note) {
          complianceMessages {
            field
            errorLevel
            message
            regex
            canHighlightErrors
            isRegexCaseSensitive
          }
          procedureMessage {
            errorCode
            sqlState
            message
          }
          timeEntryDto {
            activityId
            narrative
            note
            activityTime
            activityCode
            activityDescription
            taskCode
            taskDescription
            budgetJobPhaseId
            statusId
            billable
            createdDate
            modifiedDate
            activityDate
            transactionDate
            hours
            amount
            activityTypeCode
            activityTypeDescription
            geoLocationCode
            geoLocationDescription
            matterCode
            matterDescription
            clientCode
            clientDescription
            createdBy {
              employeeId
              firstName
              middleInitial
              lastName
              employeeType {
                id
                code
                description
              }
            }
            modifiedBy {
              employeeId
              firstName
              middleInitial
              lastName
              employeeType {
                id
                code
                description
              }
            }
            timekeeper {
              employeeId
              firstName
              middleInitial
              lastName
              employeeType {
                id
                code
                description
              }
            }
          }
        }
      }
      `;

    const variables = {
      timeEntryId,
      narrative,
      note,
    };

    const response = await axiosInstance.post("", {
      query: mutation,
      variables
    });

    // return the data from the response
    return response.data;
  },

  updateTimeEntry: async (timeEntryDto) => {
    const mutation = `
      mutation ($TimeEntryDto: TimeEntryDto) {
        updateTimeEntry(timeEntryDto: $TimeEntryDto) {
          timeEntryId
          releasedBy
          postedById
          activityTypeId
          activityDate
          narrative
          note
          writeOff
          doNotCharge
          rateCode
          hours
          amount
          statusId
          timeKeeper{
            timeKeeperId
          }
          matter{
            matterId
          }
          client {
            clientId
          }
          serviceCode {
            servicecodeid
          }
        }
      }
    `;

    const response = await axiosInstance.post("", {
      query: mutation,
      variables: { TimeEntryDto: timeEntryDto },
    });

    // return the data from the response
    return response.data;
  },

  getTimeEntryById: async (timeEntryId) => {
    const mutationQuery = `
    query{
      getTimeEntryById (timeEntryId: ${timeEntryId})  {
        activityId
        narrative
        note
        activityTime
        activityCodeId
        activityCode
        activityDescription 
        taskCodeId
        taskCode
        taskDescription
        budgetJobPhaseId
        transactionDate
        statusId
        billable
        createdDate
        modifiedDate
        activityDate
        transactionDate
        hours
        amount
        activityTypeCode
        activityTypeDescription
        geoLocationId
        geoLocationCode
        geoLocationDescription
        customerId
        matterCode
        matterDescription
        clientId
        clientCode
        clientDescription
        rate
        quantity
        empNameType
        createdBy {
            employeeId
            firstName
            middleInitial
            lastName
            employeeType {
                id
                code
                description
            }
        }
        modifiedBy {
            employeeId
            firstName
            middleInitial
            lastName
            employeeType {
                id
                code
                description
            }
        }
        timekeeper {
            employeeId
            firstName
            middleInitial
            lastName
            taxExempt
            employeeType {
                id
                code
                description
            }
        }
        timerDto {
            timerId
            activityId
            createdById
            createdDate
            modifiedById
            modifiedDate
            timerStartDateTime
            timerStopDateTime
            timeElapsed
        }
        }
    }
  `;

    const response = await axiosInstance.post("", {
      query: mutationQuery,
    });

    return response.data;
  },

  updateMultipleTimeEntry: async (timeEntryDto) => {
    const mutation = `
    mutation updateMultiple ($TimeEntryDto: [TimeEntryDto]!){
      updateMultipleTimeEntry(timeEntryDto: $TimeEntryDto)
      {
        success
        message
      }
    }
  `;
    const response = await axiosInstance.post("", {
      query: mutation,
      variables: { TimeEntryDto: timeEntryDto },
    });

    // return the data from the response
    return response.data;
  },

  deleteTimeEntry: async (timeEntryIds) => {
    const mutation = `
      mutation deleteTimeEntry($TimeEntryDeleteRequest: TimeEntryDeleteRequest) {
        deleteTimeEntry(timeEntryDeleteRequest: $TimeEntryDeleteRequest)
      }
    `;
    const response = await axiosInstance.post("", {
      query: mutation,
      variables: {
        TimeEntryDeleteRequest: {
          timeEntryIds: timeEntryIds,
        },
      },
    });

    return response.data;
  },

  getRecentClientMatterList: async () => {
    var query = `
    query {
      getRecentClientMatters{
        clientMatters{
                clientId
                clientCode
                clientDesc
                matterId
                matterCode
                matterDesc
                description
        }
    }
    }
  `;
    //Creating Temporary instance because it has different Base URL.
    axios.defaults.baseURL = config.graphql_URL_TEMP;
    axios.defaults.headers.sid = localStorage.getItem("sid");
    const response = await axios.post("", {
      query: query,
    });

    return response.data;
  },

  getEntryTypes: async () => {
    var query = `
    query {
      getEntryTypes {
          id
          code
          description
      }
  }
  `;
    const response = await axiosInstance.post("", {
      query: query,
    });

    return response.data;
  },

  searchClientMatters: async (searchText) => {
    const mutationQuery = `
      query CreateClientMatter($searchText: String!) {
        searchClientMattersImpl(searchText: $searchText, moduleType: "FEE") {
          clientMatters {
            clientId
            clientCode
            clientDesc
            matterId
            matterCode
            matterDesc
            description
            billableType {
              id
              code
            }
            enforceBillableFlag
          }
        }
      }
    `;

    //Creating Temporary instance because it has different Base URL.
    axios.defaults.baseURL = config.graphql_URL_TEMP;
    axios.defaults.headers.sid = localStorage.getItem("sid");

    const response = await axios.post("", {
      query: mutationQuery,
      variables: {
        searchText: searchText,
      },
    });

    return response?.data?.data?.searchClientMattersImpl?.clientMatters || [];
  },

  searchLocations: async (searchText, customerId) => {
    const mutationQuery = `
    query getLocations($code: String, $description: String, $searchText: String,$matterId: Int) {
      getLocations(code: $code, description: $description, searchText: $searchText, matterId: $matterId) {
        locations{
          id
          code
          description
        }
      }
    }
  `;

    //Creating Temporary instance because it has different Base URL.
    axios.defaults.baseURL = config.graphql_URL_TEMP;
    axios.defaults.headers.sid = localStorage.getItem("sid");

    const response = await axios.post("", {
      query: mutationQuery,
      variables: {
        searchText: searchText,
        code: null,
        description: null,
        matterId: parseInt(customerId)
      }
    });

    return response?.data?.data?.getLocations?.locations || [];
  },

  searchActivityCode: async (searchText, customerId) => {
    const mutationQuery = `
    query searchActivityCodes($code: String, $description: String, $searchText: String, $matterId: Int ) {
      searchActivityCodes(code: $code, description: $description, searchText: $searchText, matterId: $matterId) {
        id
        code
        description
      }
    } 
  `;

    const response = await axiosInstance.post("", {
      query: mutationQuery,
      variables: {
        searchText: searchText,
        code: null,
        description: null,
        matterId: parseInt(customerId)
      }
    });

    return response?.data?.data?.searchActivityCodes || [];
  },

  searchTaskCode: async (searchText, customerId) => {
    const mutationQuery = `
      query searchTaskCodes($taskCodeSearchRequest: TaskCodeSearchRequest) {
        searchTaskCodes(taskCodeSearchRequest: $taskCodeSearchRequest){
        id
        code
        description
        }
      }
    `;

    const variables = {
      taskCodeSearchRequest: {
        code: null,
        description: null,
        searchText: searchText,
        matterId: customerId,
      },
    };

    const response = await axiosInstance.post("", {
      query: mutationQuery,
      variables,
    });

    return response?.data?.data?.searchTaskCodes || [];
  },

  getRateByTaskCode: async (timeEntry) => {
    const mutationQuery = `
      query getRateByTaskCode($taskCodeId: Int, $jobId: Int, $activityDate: String) {
        getRateByTaskCode(taskCodeId: $taskCodeId, jobId: $jobId, activityDate: $activityDate){
          rate
        }
      }
    `;

    const response = await axiosInstance.post("", {
      query: mutationQuery,
      variables : {
        taskCodeId: timeEntry.taskCodeId,
        jobId: timeEntry.jobId,
        activityDate: timeEntry.activityDate
      },
    });

    return response?.data?.data?.getRateByTaskCode || [];
  },

  getTimeKeepers: async (searchText, customerId) => {
    const mutationQuery = `
    query GetMatchedTimeKeepers($searchText: String!, $matterId: Int){
      getTimeKeepers(searchText: $searchText, matterId: $matterId){
          timeKeepers{
            id
            code
            description
            employeeEntity {
              taxExempt
            }
          }
      }
  }`;

    // //Creating Temporary instance because it has different Base URL.
    axios.defaults.baseURL = config.graphql_URL_TEMP;
    axios.defaults.headers.sid = localStorage.getItem("sid");

    const response = await axios.post("", {
      query: mutationQuery,
      variables: {
        searchText: searchText,
        matterId: parseInt(customerId)
      },
    });
    let timekeepers = response?.data?.data?.getTimeKeepers?.timeKeepers || [];

    return timekeepers;
  },

  getTotalForTimeEntry: async (timeEntry) => {
    var mutationQuery = `
    query getTotalForTimeEntry($timeKeeperId: Int, $jobId: Int, $activityCodeId: Int, $activityDate: String, $hours: Float) {
      getTotalForTimeEntry(timeKeeperId: $timeKeeperId, jobId: $jobId, activityCodeId: $activityCodeId, activityDate: $activityDate, hours: $hours) {
        amount
      }
    }
  `;
    const response = await axiosInstance.post("", {
      query: mutationQuery,
      variables: {
        timeKeeperId: timeEntry.timeKeeperId,
        jobId: timeEntry.jobId,
        activityCodeId: null,
        activityDate: timeEntry.activityDate,
        hours: timeEntry.hours,
      },
    });

    return response?.data?.data?.getTotalForTimeEntry || [];
  },
  getAccountingPeriod: async (transactionDate) => {
    const mutationQuery = `
      query($transactionDate: String!) {
        getAccountingPeriod(transactionDate: $transactionDate){
          id
          code
          description
          startDate
          endDate
          year
          period
          lockFee
          lockExpense
          status {
            id
            status
          }
        }
      }
    `;

    const variables = {
      transactionDate,
    };

    axios.defaults.baseURL = config.graphql_URL_TEMP;
    axios.defaults.headers.sid = localStorage.getItem("sid");
    const response = await axios.post("", {
      query: mutationQuery,
      variables
    });
    
    return response?.data?.data?.getAccountingPeriod || null;
  },
  unpostTimeEntry: async (activityId) => {
    const mutation = `
    mutation unpostTimeEntry($activityId: Int) {
      unpostTimeEntry(activityId: $activityId)
    }
  `;
    
  const variables = {
    activityId,
  };
    const response = await axiosInstance.post("", {
      query: mutation,
      variables
    });

    // return the data from the response
    return response.data;
  },
};
export default timeEntryMutation;
