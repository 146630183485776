import React, { memo } from 'react';
import { Box, Pagination } from "@mui/material";
import '../../pages/TimeEntry/TimeEntryGrid/TimeEntryDataGrid.scss';
import {useDispatch } from "react-redux";
import { setPage } from "../../redux-toolkit/slices/timeEntries";

const CustomPaginationTimeEntryGrid = ({page,totalPages}) => {
  
  const dispatch = useDispatch();
  return (<Box sx={{ width: "100%", textAlign: "center" }}>
    <Pagination
      style={{ display: "inline-block", padding: "20px" }}
      color="primary"
      count={totalPages}
      page={page + 1}
      onChange={(event, value) => dispatch(setPage(value - 1))}
    />
  </Box>
  
  );
}

export default memo(CustomPaginationTimeEntryGrid)