import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export const SkeletonText = () => {
    return (
        <Skeleton variant="rectangular" width={30} height={15} sx={{ marginLeft: '5px' }} />
    );
};

export const SkeletonProgressBar = () => {
    return (
        <div>
            <div style={{ display: 'flex', gap: 8, paddingTop: 12, paddingLeft: 10 }}>
                <Skeleton variant="rounded" width={'85%'} height={13} />
                <Skeleton variant="rectangular" width={'10%'} height={13} sx={{ marginLeft: '5px' }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 12 }}>
                <Skeleton variant="rectangular" width={'75%'} height={15} />
            </div>
        </div>
    );
};

export const SkeletonCard = ({ minCount, maxCount }) => {
    const count = Math.floor(Math.random() * maxCount) + minCount;
    const skeletonCards = Array.from({ length: count }, (_, index) => (
      <Card key={index} style={SkeletonCardStyle}>
        <CardContent>
          <React.Fragment>
            <Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={20} width="60%" />
            <div style={SkeletonCardDiscription}>
              <Skeleton animation="wave" height={20} width="30%" />
              <Skeleton animation="wave" height={20} width="20%" />
            </div>
          </React.Fragment>
        </CardContent>
      </Card>
    ));
  
    return <div>{skeletonCards}</div>;
};

const SkeletonCardStyle={
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    padding: "12px",
    height: "110px",
    margin : "12px",
    width : "100%"
}
const SkeletonCardDiscription={
    display : "flex",
    gap : "15px",
    marginTop : "12px"
}