export const timeEntryColumns=[
    { name : "Activity" ,field : "activity", isVisible : true },
    { name : "Created Date" ,field : "createdDate", isVisible : false },
    { name : "Hours" ,field : "hours", isVisible : true },
    { name : "Modified Date" ,field : "modifiedDate", isVisible : false },
    { name : "Posted By" ,field : "postedBy", isVisible : false },
    { name : "Task" ,field : "taskCode", isVisible : true },
    { name : "Billable" ,field : "billable", isVisible : false },
    { name : "Date" ,field : "date", isVisible : true },
    { name : "Location" ,field : "location", isVisible : true },
    { name : "Narrative" ,field : "description", isVisible : true },
    { name : "Service" ,field : "service", isVisible : false },
    { name : "Timekeeper" ,field : "timekeeper", isVisible : true },
    { name : "Client" ,field : "clientName", isVisible : true },
    { name : "Entry Date" ,field : "activityDate", isVisible : false },
    { name : "Matter" ,field : "matterName", isVisible : true },
    { name : "Note" ,field : "note", isVisible : false },
    { name : "Start Time" ,field : "startTime", isVisible : false },
    { name : "Type" ,field : "type", isVisible : true },
    { name : "Created By" ,field : "createdBy", isVisible : false },
    { name : "Entry ID" ,field : "entryId", isVisible : false },
    { name : "Modified By" ,field : "modifiedBy", isVisible : false },
    { name : "Phase" ,field : "phase", isVisible : false }, 
    { name : "Status" ,field : "status", isVisible : true }, 
    { name : "Rate" ,field : "rate", isVisible : true },
    { name : "Quantity" ,field : "quantity", isVisible : true }, 
    { name : "Transaction Date" ,field : "transactionDate", isVisible : false },
    { name : "Period Date" ,field : "periodDate", isVisible : false },
    { name : "Tax Exempt" ,field : "taxExempt", isVisible : false },
]