import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import StyledButton from "./StyledButton"
import { literals } from '../../enums/literalCodes';
import jsUtils from "../../utils/jsUtils.js"
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { useSelector, useDispatch } from 'react-redux';
import { startTimer, stopTimer, resetTimer, updateTimer, setGridStartedTimer,setGridStoppedTimer } from '../../redux-toolkit/slices/timer';
import { handleTimerEvent } from "../../redux-toolkit/slices/timerSlice.js";
import "./Timer.scss";

const CustomTimer = ({
    status, // Posted/Draft etc.
    Id, //widget Id || activityId
    isActivitiyId, // Boolean value
    handleAddtoHours, // Callback
    previousTime, //fetched from DB
    Component, //Grid | TimeEntry,
    timerDTO = null, // timer object of saved time entry
    onTimerClicked=()=>{}
}) => {

    const dispatch = useDispatch();
    const timer = useSelector((state) => state.timerController.timers[Id]);
    let time = typeof (timer) !== "undefined" ? timer.time : Math.max(previousTime, 0);
    
    const onStartTimer = () => {
        const intervalId = setInterval(() => {
            dispatch(updateTimer({ timerId: Id }));
        }, 1000);

        dispatch(startTimer({ timerId: Id, intervalId, time, component: Component }));
    }

    const handleStart = () => {
        if (!timer || !timer.isTracking || timer.isStopped || Id !== timer?.timerId) {
            onTimerClicked(Id,literals.STARTED)
            timeEntryTimerEventRequest(literals.STARTED)
            onStartTimer()
        }
        else {
            onStartTimer()
        }
    };

    const handleRunningTimerForGrid = () => {
        if (timerDTO) {
            // Timer is running
            if (!timerDTO?.timerStopDateTime && timerDTO?.timerStartDateTime) {
                dispatch(setGridStartedTimer({ timerId: Id, time, timerDTO }))
                handleStart();
                return false; // timer is running then return false
            }
            return true;
        }

    }

    const handleStoppedTimerForGrid = () => {
        if (timerDTO) {
            if (timerDTO?.timerStopDateTime && timerDTO?.timerStartDateTime) {
                handleStop();
                return true; // timer has been stopped then return true
            }
            return false;
        }

    }

    const handleStop = () => {

        if (timer && timer.isTracking && !timer.isStopped) {
            onTimerClicked(Id,literals.STOPPED)
            timeEntryTimerEventRequest(literals.STOPPED)
            dispatch(stopTimer({ timerId: Id, time }));
        }
        else if (!timer && timerDTO) {
            // onStartTimer();
            dispatch(setGridStoppedTimer({ timerId: Id, time, timerDTO }));
        }
    };

    const OnhandleAddtoHours = () => {

        if (timer) {
            onTimerClicked(Id,literals.RESET)
            dispatch(resetTimer({ timerId: Id }));
            timeEntryTimerEventRequest(literals.RESET);
        }
        else {
            onStartTimer();
            dispatch(resetTimer({ timerId: Id }));
        }

        handleAddtoHours(Id, time)
    }

    const timeEntryTimerEventRequest = (event) => {

        if (!isActivitiyId) {
            jsUtils.updateTimerDto(Id, event);
        }
        else {
            const timerRequest = jsUtils.timeEntryTimerEventRequest(event);
            dispatch(handleTimerEvent(timerRequest))
                .then((res) => {
                    console.log(res?.registerTimerEvent)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const handleReset = () => {
        if (timer) {
            onTimerClicked(Id,literals.RESET)
            timeEntryTimerEventRequest(literals.RESET)
            dispatch(resetTimer({ timerId: Id }));
        }
        else {

            onStartTimer()
            dispatch(resetTimer({ timerId: Id }));
            timeEntryTimerEventRequest(literals.RESET)
        }
    };

    let isTracking = typeof (timer) !== "undefined" ? timer.isTracking : (previousTime !== 0 && handleRunningTimerForGrid()) ? true : false;
    let isStopped = typeof (timer) !== "undefined" ? timer.isStopped : (previousTime !== 0 && handleStoppedTimerForGrid()) ? true : false;

    const btnStyles = { color: '#0050C3', fontSize: '13px', fontWeight: 500, minWidth: "0px", minHeight: "0px", padding: "0px" , width : "45px"}
    const btnAddtoHour = { color: '#0050C3', fontSize: '13px', fontWeight: 500, minWidth: "0px", minHeight: "0px", padding: "0px" , width : "85px"}

    return (

        <div className="timer-container">
            <div className="timer-buttons" style={{ padding: !isTracking ? "5px 11px" : "0px 11px" }}>
                {isTracking ? (
                    <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                        <StyledButton
                            onClick={handleReset}
                            style={btnStyles}
                        >
                            {literals.RESET_LowerCase}
                        </StyledButton>

                        <div className="timeValue">{jsUtils.formatTime(time)}</div>

                        {
                            isStopped ? <IconButton
                                disableRipple
                                sx={{ minWidth: "0px", minHeight: "0px", padding: "0px" }}
                            >
                                <Tooltip title={literals.START}>
                                    <PlayCircleFilledIcon
                                        className="icons"
                                        onClick={handleStart}
                                    />
                                </Tooltip>
                            </IconButton>
                                :
                                <IconButton
                                    disableRipple
                                    sx={{ minWidth: "0px", minHeight: "0px", padding: "0px" }}
                                >
                                    <Tooltip title={literals.STOP}>
                                        <StopCircleIcon onClick={handleStop}
                                            className="stopIcons" />
                                    </Tooltip>
                                </IconButton>
                        }

                        <div>
                            <StyledButton onClick={() => (status == literals.DRAFT || status == literals.TAGGED) && OnhandleAddtoHours()} style={btnAddtoHour} >{literals.ADD_TO_HOURS}</StyledButton>
                        </div>

                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                        <IconButton
                            disableRipple
                            sx={{ minWidth: "0px", minHeight: "0px", padding: "0px" }}
                        >
                            <Tooltip title={literals.START}>
                                <PlayCircleFilledIcon
                                    className="icons"
                                        onClick={(status == literals.DRAFT || status == literals.TAGGED) && handleStart}
                                        style={{ cursor: (status == literals.DRAFT || status == literals.TAGGED) ? 'pointer' : 'default' }}
                                />
                            </Tooltip>
                        </IconButton>

                        <div className="timeValue">{jsUtils.formatTime(time)}</div>
                    </div>
                )}
            </div>
        </div>

    );
}

export default CustomTimer;