import "./TimeEntryDataGrid.scss"

import React, { memo, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CustomGridToolbarContainer from "../../../components/common/CustomGridToolbarContainer";
import CustomSearch from "./CustomSearch"
import CustomToggleColumn from "./CustomToggleColumn"
import {
    GridRowModes
} from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import StyledButton from "../../../components/common/StyledButton"
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { literals } from "../../../enums/literalCodes";
import { randomId } from '@mui/x-data-grid-generator';
import timeEntryService from "../../../services/timeEntryService";

const CustomGridToolBar = (props) => {
    const { handleColumnVisibilityChange, timeColumns, DataGridRef, setRows, setRowModesModel, rowSelectionModel, rows, postMultipleRows, setFilteredRows, handleDeleteClick, widgetId, removeWidgetFromDashboard, fetchEntries, setEditRowId ,editRowId} = props;
    const SELECTED_ROW_LENGTH = rowSelectionModel.length;
    const hasPostedAllowed = rows.some(item => item.status === literals.DRAFT_READY_TO_POST);

    //For column toggle popper.
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openToggleColumn, setOpenToggleColumn] = React.useState(false);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedOperator, setSelectedOperator] = useState('');
    const [searchText, setSearchText] = useState('');
    const [defaultTimekeeper, setDefaultTimekeeper] =
    useState(null);

    useEffect(()=>{
        getDefaultTimeKeeper();
    },[]);

    const getDefaultTimeKeeper = async () => {
        await timeEntryService
        .getTimeKeepers("")
        .then((data) => {
            const timeKeeper = data.find(({ id })=>id==localStorage.getItem("empId"));
            setDefaultTimekeeper(timeKeeper);
        })
        .catch((e) => {
            console.error(e);
        });
    };

    const handleClickToggle = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenToggleColumn((prev) => !prev);
    };

    const handleClickSearch = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenSearch((prev) => !prev);
    };

    const handleClick = () => {
        if(!editRowId){
            let id =  Math.floor(10000000 + Math.random() * 90000000);
            let newRow = { id:id, activityId: null, employeeId: defaultTimekeeper? defaultTimekeeper?.id:null, timekeeper: defaultTimekeeper?.description || '', clientId: null, customerId: null, description: "", clientName: "", matterName: "", activityDate: dayjs(new Date()).format('YYYY-MM-DD'), date: dayjs(new Date()), hours: 0, amount: 0, time: 0, rate : 0, status: "Draft", istracker: false, isNew: true };
            setRows((oldRows) => [newRow, ...oldRows]);
            setEditRowId(id);
            setRowModesModel(() => ({
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'clientName' },
            }));
        }
        // startAutoSaveTimer(newRow);
    };

    const handleColumnChange = (name, value) => {
        switch(name) {
            case 'column' :
                return setSelectedColumn(value);
            case 'operator' :
                return setSelectedOperator(value);
            case 'search' :
                return setSearchText(value);
            default:
                return true;
        }
    }
    const setClearFilters = () => {
        setSelectedColumn('');
        setSelectedOperator('');
        setSearchText('');
    }

    return (
        <CustomGridToolbarContainer
            title={literals.TIME_ENTRY_GRID}
            secondary={
                <div className='toolbar-button-group'>

                    {SELECTED_ROW_LENGTH > 0 &&
                        <div className='select-all-buttons'>
                            <StyledButton onClick={() => handleDeleteClick(rowSelectionModel)} size="small" variant="outlined">{SELECTED_ROW_LENGTH > 1 ? literals.DELETE_ALL : literals.DELETE}</StyledButton>
                            {hasPostedAllowed && <StyledButton size="small" variant="contained" onClick={postMultipleRows} >{SELECTED_ROW_LENGTH > 1 ? literals.POST_ALL : literals.POST}</StyledButton>}
                        </div>
                    }

                    {!openToggleColumn && <Tooltip title="Add Time Entry Row">
                        <IconButton onClick={() => handleClick()} >
                            <AddIcon className="MuiIcons" />
                        </IconButton>
                    </Tooltip>
                    }
                    <Tooltip title="Refresh">
                        <IconButton onClick={() => fetchEntries()} >
                            <RefreshIcon className="MuiIcons" />
                        </IconButton>
                    </Tooltip>
                    {!openToggleColumn &&
                        <Tooltip title={!openSearch ? "Show Filters" : "Hide Filters"}>
                            <IconButton onClick={(event) => handleClickSearch(event)}>
                                <SearchIcon className="MuiIcons" />
                            </IconButton>
                        </Tooltip>
                    }
                    {openSearch &&
                    <CustomSearch setFilteredRows={setFilteredRows} open={openSearch} anchorEl={anchorEl} rows={rows} selectedColumn={selectedColumn} selectedOperator={selectedOperator} searchText={searchText} handleColumnChange={handleColumnChange} setClearFilters={setClearFilters}/>
                }
                    <Tooltip title="Settings">
                        <IconButton onClick={(event) => handleClickToggle(event)}>
                            <SettingsIcon className="MuiIcons" />
                        </IconButton>
                    </Tooltip>
                    {/* toggle column layout */}
                    {openToggleColumn &&
                        <CustomToggleColumn DataGridRef={DataGridRef} columns={timeColumns} open={openToggleColumn} handleColumnVisibilityChange={handleColumnVisibilityChange} anchorEl={anchorEl} />
                    }
                    {!openToggleColumn && <Tooltip title={literals.REMOVE_WIDGET}>
                        <IconButton onClick={() => removeWidgetFromDashboard(widgetId)}>
                            <CloseIcon className="MuiIcons" />
                        </IconButton>
                    </Tooltip>}
                </div>
            }
        >
        </CustomGridToolbarContainer>


    );
}

export default memo(CustomGridToolBar)
