import React, { useState, memo } from 'react';
import {Autocomplete} from '@mui/material';
import CssTextField from "./CssTextField.jsx";
import InputAdornment from '@mui/material/InputAdornment';
import StyledTooltip from "./StyledTooltip.jsx";
import jsUtils from '../../utils/jsUtils.js';

const AutoCompleteExpense = ({ state, errors, value, getOptionLabel, field, label, options, selectedOption, setSelectedOption, setOptionsChanges}) => {

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    }

    const handleOptionChange = (event, SelectedOptions) => {
        setSelectedOption(SelectedOptions);
        setOptionsChanges(SelectedOptions,field, state);
      };

    return (
        <Autocomplete
            id="expense"
            options={options}
            getOptionLabel={getOptionLabel}
            style={{width: "100%"}}
            onChange={handleOptionChange}
            freeSolo
            value={selectedOption}
            renderInput={params => (
                <CssTextField
                    {...params}
                    label={label}
                    variant="outlined"
                    size="small"
                    color={jsUtils.getFieldSeverity(errors[field])}
                    value={inputValue}
                    error={errors[field]}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                                {!!errors[field] && (
                                    <InputAdornment position="end">
                                        <StyledTooltip
                                            title={errors[field].message}
                                            severity={errors[field].errorLevel}
                                            placement="left"
                                            arrow
                                        />
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                    onChange={(event) => handleInputChange(event, event.target.value)}
                />
            )}
        />
    );
};

export default memo(AutoCompleteExpense)