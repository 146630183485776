import React from 'react';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
  } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import config from "../../../../config"
  
  const httpLink = createHttpLink({
    uri: config.graphql_URL_TEMP,
  });
  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("sid");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        sid: token,
      },
    };
  });
  
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  
  const ApolloContextProvider = ({children}) => {
    return (
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
    )
  }
  
  export default ApolloContextProvider;
