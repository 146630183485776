import { Box } from "@mui/material";
import "./App.scss";
import "@fontsource/roboto";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import React, { useEffect, useState } from "react";
import jsUtils from "./utils/jsUtils";
import Dashboard from "./pages/Dashboard/Dashboard.jsx";
import ApolloContextProvider from "./pages/Dashboard/GraphQL/ApolloContext.js";

import "./sassStyles/global.scss";

export default function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [tabURL, setTabURL] = useState(null);

  useEffect(() => {
    const sid = jsUtils.parseHash(window.location.hash)?.["sid"];
    if (sid) {
      localStorage.setItem("sid", sid);
      window.history.replaceState({}, "", "/TimeEntry");
      setUserLoggedIn(true);
    } else {
      if (localStorage.getItem("sid")) {
        window.history.replaceState({}, "", "/TimeEntry");
        setUserLoggedIn(true);
      } else {
        console.log("no sid found");
      }
    }
  }, []);

  //fetching the tab value from href.
  useEffect(() => {

    const handlePopState = () => {

      const tabValue = jsUtils.getQueryParam('tabId');
      setTabURL(tabValue)
      if(tabValue){ 
        window.history.replaceState({}, "", "/TimeEntry")
      }

    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };

  }, []);

  return (
    <StyledEngineProvider injectFirst>
      {userLoggedIn ? (
        <div className="App">
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "inline-block",
              // margin: "20px",
            }}
          >
            <ApolloContextProvider>
             <Dashboard tabURL={tabURL} />
            </ApolloContextProvider>
          </Box>
        </div>
      ) : (
        <></>
      )}
    </StyledEngineProvider>
  );
}
