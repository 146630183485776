export const timeEntryModel = {
    modifiedById: 1,
    hours: 0,
    quantity: 0,
    rate: null,
    amount: "",
    activityDate: "",
    isAdjusted: null,
    adjustedDate: null,
    activityType: "TimeFee",
    geoLocationId: null,
    employeeId: null,
    customerId: 1,
    billable: 0,
    activityTime: "00:00:00",
    timeElapsed: null,
    timeTotal: null,
    taskCodeId: null,
    activityCodeId: null,
    description: null,
    note: "",
    doNotCharge: "",
    writeOff: "",
    billGenerate: null,
    calendarEventId: null,
    budgetPhaseId: null,
    flatFee: null,
    clientId : 1
}