import React,{useState} from 'react';
import CommonDialog from './commonDialog';
import CloseIcon from '@mui/icons-material/Close';
import CssTextField from "./CssTextField.jsx";
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { literals } from '../../enums/literalCodes';
import StyledButton from "./StyledButton"
import InputAdornment from "@mui/material/InputAdornment";

import './commonDialog.scss';

function DialogTitle({closeModel}){
  return <div className='titleContainer'>

    <div className='title'>{literals.COURTESY_DISCOUNT}</div>
    
    <CloseIcon className='MuiIcons' onClick={closeModel}/>
  </div>
}

const AmountDialog = ({ open = false, closeModel, handleSubmit}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [discountType, setDiscountType]= useState(literals.HOURS);
  const [amount, setAmount]= useState("0.00");


  const handleChange = (event, check) => {
    if(check == "discount"){
      setDiscountType(event.target.value)
    }else{
      setAmount(event.target.value);
    }
  }

  const isSaveDisabled = () => {

    let amt = amount.split(".")[1]?.length > 0 ? amount : amount + ".00";
  
    return parseFloat(amt) > 0 ? false : true
  
  }

  const onSubmit = () => {
    
    closeModel();
    let amt = amount.split(".")[1]?.length > 0 ? amount : amount + ".00";
    
    if(parseFloat(amt) > 0){
      let finalValue = `${literals.COURTESY_DISCOUNT}: ${discountType === literals.AMOUNT ? "$" : ""}${amt} ${discountType === literals.HOURS ? (+amt > 1 ? 'Hours' : 'Hour') : ''}`;
      handleSubmit(finalValue);
      
      setAmount("0.00");
      setDiscountType(literals.HOURS);
    }
   
  }

return (
  <CommonDialog open={open} title={<DialogTitle closeModel={closeModel}/>} onClose={closeModel}>

    <div style={{display: 'flex', gap: 25, padding: '5px 30px'}} >
      <CssTextField
          fullWidth
          size="small"
          select
          name="discount"
          color="primary"
          onChange={e => handleChange(e, "discount")}
          value={discountType}
          onClick={() => setOpenMenu(!openMenu)}  
          SelectProps={{
              open: openMenu,
              IconComponent: () => openMenu ? <KeyboardArrowUpIcon onClick={() => setOpenMenu(!openMenu)} className="MuiIcons" sx={{marginRight : '0.8rem'}} />
              : <KeyboardArrowDownIcon onClick={() => setOpenMenu(!openMenu)} className="MuiIcons" sx={{marginRight : '0.8rem'}} />
            }}
          style= {{fontSize: '16px'}}
          >
          <MenuItem key={0} value={literals.HOURS}>{literals.HOURS}</MenuItem>
          <MenuItem key={1} value={literals.AMOUNT}>{literals.AMOUNT}</MenuItem>
      </CssTextField>

      <CssTextField 
        fullWidth
        color="primary"
        name="amount"
        onChange={e => handleChange(e, "amount")}
        size="small"
        autoComplete="off"
        value={amount}
        type="number"
        style= {{fontSize: '16px'}}
        className='hideSpinners'
        InputProps={{
          startAdornment: discountType === literals.AMOUNT && (
            <InputAdornment position="start">$</InputAdornment>
          )
        }}
      />
    </div>

    <div style={{display: 'flex', justifyContent: 'end', gap:13, marginTop: '3rem'}}>
      <StyledButton onClick={closeModel} variant="outlined" style={{color :"#0050C3", minWidth: '60px', maxWidth: '60px', height: '33px'}} >{literals.CANCEL}</StyledButton>
      <StyledButton disabled={isSaveDisabled()} onClick={() => onSubmit(literals.SAVE)} variant="contained" style={{backgroundColor :"#0050C3", minWidth: '50px', maxWidth: '50px', height: '33px'}} >{literals.SAVE}</StyledButton>                        
    </div>
  </CommonDialog>
  );
}

export default AmountDialog;