import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { axiosInstance } from '../../axiosInstance.js';
import jsUtils from '../../utils/jsUtils.js';

const PAGESIZE = 100;

const initialState = {
  data: null,
  isLoading: false,
  timeEntries: null,
  error: null,
  page: 0, // Current page number
  totalPages: 0, // Total number of pages
};

export const addMutipleTimeEntry = createAsyncThunk(
  'graphql/addMultipleTimeEntry',
  async (timeEntryDto) => {
    const mutation = `
      mutation ($TimeEntryDto: TimeEntryDto) {
        addMultipleTimeEntry(timeEntryDto: $TimeEntryDto) {[
          timeEntryId
          releasedBy
          postedById
          activityTypeId
          activityDate
          narrative
          note
          writeOff
          doNotCharge
          isPost
          rateCode
          hours
          statusId
          matter{
              matterId
              discountbillpercent
          }
          client{
              clientId
          }
          timeKeeper{
              timekeeperid
          }
          serviceCode{
            servicecodeid
          }
        }
      ]
    }
  `;
    const response = await axiosInstance.post('', {
      query: mutation,
      variables: { TimeEntryDto: timeEntryDto },
    });

    return response;
  }
);

export const fetchTimeEntryList = createAsyncThunk(
  'graphql/getAllTimeEntries',
  async ({ page, size }) => {
    const empId = Number(localStorage.getItem("empId"));
    const mutation = `
    query{
      getAllTimeEntries (page: ${page}, size: ${PAGESIZE}, employeeId: ${empId})  {
        totalPages
        totalElements
        last
        size
        number
        numberOfElements
        first
        empty
        pageable {
            offset
            pageSize
            pageNumber
            unpaged
            paged
            sort {
                empty
                sorted
                unsorted
            }
        }
        content {
          activityId
          narrative
          note
          activityTime
          activityCodeId
          activityCode
          activityDescription
          taskCodeId
          taskCode
          taskDescription
          budgetJobPhaseId
          statusId
          billable
          createdDate
          modifiedDate
          activityDate
          transactionDate
          hours
          amount
          rate
          quantity
          activityTypeCode
          activityTypeDescription
          geoLocationId
          geoLocationCode
          geoLocationDescription
          customerId
          matterCode
          matterDescription
          clientId
          clientCode
          clientDescription
          empNameType
          createdBy {
              employeeId
              firstName
              middleInitial
              lastName
              employeeType {
                  id
                  code
                  description
              }
          }
          modifiedBy {
              employeeId
              firstName
              middleInitial
              lastName
              employeeType {
                  id
                  code
                  description
              }
          }
          timekeeper {
              employeeId
              firstName
              middleInitial
              lastName
              taxExempt
              employeeType {
                  id
                  code
                  description
              }
          }
          timerDto {
              timerId
              activityId
              createdById
              createdDate
              modifiedById
              modifiedDate
              timerStartDateTime
              timerStopDateTime
              timeElapsed
          }
        }
      }
    }
    `;

    const response = await axiosInstance.post('', {
      query: mutation,
    });

    let data = response.data?.data;

    if (data?.getAllTimeEntries?.content) {
      let { content, totalPages } = data.getAllTimeEntries;
      data.getAllTimeEntries = content
        .map((item) => jsUtils.handleTimeEntries(item))
        .sort((a, b) => b.id - a.id);
      data.totalPages = totalPages;
    }
    return data;
  }
);

const timeEntrySlice = createSlice({
  name: 'timeEntries',
  initialState,
  reducers: {
    // Other reducer cases
    setPage: (state, action) => {
      state.page = action.payload;
    },
    updateTimeEntries:(state, action) => {
      state.timeEntries=action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // creating cases for fetchTimeEntryList API calls
      .addCase(fetchTimeEntryList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTimeEntryList.fulfilled, (state, action) => {
        state.timeEntries = action.payload?.getAllTimeEntries;
        state.isLoading = false;
        state.error = null;
        state.totalPages =
          action.payload?.totalPages || state.totalPages;
      })
      .addCase(fetchTimeEntryList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setPage } = timeEntrySlice.actions;
export default timeEntrySlice.reducer;
