export const expenseEntryModel = {
    id: -1,
    ts: null,
    modifiedById: 2,
    modifiedDate: null,
    rate: 33,
    amount: 43,
    activityTypeId: 103,
    activityType: "Expense",
    activityDate: "",
    isAdjusted: false,
    adjustedDate: "",
    employeeId: 104,
    customerId: 56,
    billable: null,
    quantity: 53,
    expenseCodeId: 5,
    geoLocationId: null,
    description: "",
    invoiceId: null,
    note: "",
    doNotCharge: false,
    writeOff: false,
    budgetPhaseId: null
}