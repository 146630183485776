import { FormControl, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import CssTextField from './CssTextField.jsx';
import StyledTooltip from './StyledTooltip';

const suggestionsStyle = {
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  position: 'absolute',
  height: 'auto',
  maxHeight: '250px',
  overflow: 'auto',
  width: '100%',
  top: '35px',
  backgroundColor: 'white',
  border: 'black',
  zIndex: 10000,
  paddingTop: '10px',
  paddingBottom: '10px',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
  color: 'black !important',
};

const defaultOptionStyle = {
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '12px',
  paddingRight: '12px',
};

const hoverOptionStyle = {
  backgroundColor: '#f5f5f5',
};

const AutoCompleteCustom = ({
  setOptionsChanges,
  searchSuggestion,
  searchId,
  errors,
  field,
  label,
  disabled,
  selectedTimekeeper,
  state={}
}) => {
  const [selectedOption, setSelectedOption] = useState(
    selectedTimekeeper || ''
  );

  useEffect(() => {
    setSelectedOption(selectedTimekeeper?.description);
    setSuggestions(selectedTimekeeper);
  }, [selectedTimekeeper]);

  const [suggestions, setSuggestions] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setHoveredIndex((prevIndex) =>
          prevIndex === null ? 0 : (prevIndex + 1) % suggestions.length
        );
        break;
      case 'ArrowUp':
        event.preventDefault();
        setHoveredIndex((prevIndex) =>
          prevIndex === null ? suggestions.length - 1 : (prevIndex - 1 + suggestions.length) % suggestions.length
        );
        break;
      case 'Enter':
        if (hoveredIndex !== null) {
          handleSuggestionClick(suggestions[hoveredIndex]);
        }
        break;
      case 'Tab':
        if (suggestions.length === 1) {
          setSelectedOption(suggestions[0].description);
          setSuggestions([]);
          if (setOptionsChanges) {
            setOptionsChanges(suggestions[0], 'employeeId');
          }
        } else {
          setSuggestions([]);
          handleClickAway();
        }
        break;
      default:
        break;
    }
  };

  const handleInputChange = (event) => {
    setSelectedOption(event.target.value);

    if (searchSuggestion) {
      searchSuggestion(event.target.value, searchId, state)
        .then((data) => {
          const mappedData = data.map((item) => ({
            code: item.code,

            description: item.description,
            id: item.id,
            employeeEntity: item.employeeEntity,
          }));

          setSuggestions(mappedData);
          setOpen(true);
        })

        .catch((e) => {
          setSuggestions([]);

          console.error(e);
        });
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedOption(suggestion.description);

    setSuggestions([]);

    if (setOptionsChanges) {
      setOptionsChanges(suggestion, 'employeeId');
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const clearInput = () => {
    setSelectedOption('');
  };

  const handleOnFocus = (event) => {
    handleInputChange(event);
    if (searchSuggestion?.length > 0) {
      setOpen(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl style={{ width: '100%' }}>
        <CssTextField
          label={label}
          variant="outlined"
          size="small"
          fullWidth
          value={selectedOption}
          InputLabelProps={{ shrink: true }}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          error={!!errors[field]}
          InputProps={{
            tabIndex: -1,
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <div
                    style={{ paddingTop: '6px', paddingRight: '5px' }}
                  >
                    {errors[field] && (
                      <StyledTooltip
                        title={errors[field].message}
                        severity={errors[field].errorLevel}
                        placement="left"
                        arrow
                      />
                    )}

                    {selectedOption && !disabled && (
                      <CloseIcon
                        fontSize="small"
                        onClick={clearInput}
                      />
                    )}
                  </div>
                </InputAdornment>
              </>
            ),
          }}
          style={{ position: 'relative' }}
          onFocus={handleOnFocus}
        />

        {suggestions?.length > 0 && open && (
          <div style={suggestionsStyle}>
            {suggestions.map((suggestion, index) => (
              <div
                key={suggestion?.code}
                onClick={() => handleSuggestionClick(suggestion)}
                style={{
                  ...defaultOptionStyle,
                  ...(index === hoveredIndex && hoverOptionStyle),
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {suggestion.description}
              </div>
            ))}
          </div>
        )}
      </FormControl>
    </ClickAwayListener>
  );
};

export default AutoCompleteCustom;
