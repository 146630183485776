import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const StyledTooltip = (props) => {
  const [showPoper, setShowPoper] = useState(false);
  const errorList = ["DisableFinalize", "DisablePost", "DisableSave"];
  
  return (
    <Tooltip open={showPoper} arrow {...props}
      componentsProps={{
        popper: {
          sx: {
            paddingLeft: '10px'
          }
        },
        arrow: {
          sx: {
            color: errorList.includes(props.severity) ? "#D32F2F" : "#FF9800"
          }
        },
        tooltip: {
          sx: {
            color: errorList.includes(props.severity) ? "white" : "black",
            fontSize: '14px',
            backgroundColor: errorList.includes(props.severity) ? "#D32F2F" : "#FF9800",
            fontWeight: 400,
            lineHeight: '24px',
            padding: '4px 8px 4px 8px',
            maxWidth: '300px',
          }
        }
      }}>
      {errorList.includes(props.severity) ? 
      <WarningAmberIcon fontSize='medium' style={{ color: '#D32F2F' }} onClick={() => setShowPoper(!showPoper)}
        onMouseOver={() => setShowPoper(true)} onMouseOut={() => setShowPoper(false)} /> :
      <InfoOutlinedIcon fontSize='medium' style={{ color: "#FF9800" }} onClick={() => setShowPoper(!showPoper)}
        onMouseOver={() => setShowPoper(true)} onMouseOut={() => setShowPoper(false)} />
      }
    </Tooltip>
  )
};

export default StyledTooltip;