import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./App";
import { store } from './redux-toolkit/store';
import { Provider } from 'react-redux';
import ToastMessage from "./components/common/ToastMessage";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: () => {
    return <Provider store={store}>
      <Root />
      <ToastMessage />
    </Provider>
  },
  errorBoundary(err) {
    // Customize the root error boundary for your microfrontend here.
    //return null;
    console.error(err);
    return <div>Something went wrong! Check the console for errors</div>;
  },
});
export const { bootstrap, mount, unmount } = lifecycles;
